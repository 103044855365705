@charset "UTF-8";
@import "variables";
/* title */

.project__number{
	color: $main-color;
	dt{
		font-size: 1.1rem;
		font-weight: 500;
	}
	dd{
		font-size: 3vw;
		font-weight: 900;
		&.j-title{
			font-size: 2vw;
		}
	}
	&.black{
		color: #000;
	}
}
h3.page-top-title{
	color: $main-color;
	font-size: 2.5vw;
	font-weight: bold;
	margin-bottom: 100px;
	padding-top: 100px;
	width: 100%;
	text-align: center;
}
.page-top-text{
	margin: 5% auto 0;
	font-size: 2rem;
	font-weight: bold;
	text-align: center;
	line-height: 2em;
}
.page-title-box{
	width: 100%;
	margin: 50px auto 100px;
	max-width: 1000px;
	text-align: center;
	.page-title{
		font-size: 4rem;
		padding-bottom: .5em;
		position: relative;
		margin-bottom: 1em;
		overflow: hidden;
		&::before{
			content: '';
			width: 100%;
			height: 3px;
			background:  $main-color;
			position: absolute;
			bottom: 0;
			left:  -100%;
			transition:.3s all ease-in 1s;
		}
		span{
			font-weight: bold;
			letter-spacing: 0.3em;
			display: block;
			overflow: hidden;
			position: relative;
			&.english{
				color: $main-color;
				font-size: 0.4em;
				font-family: brandon-grotesque, sans-serif;
				font-style: oblique; 
				&::before{
					transition:.2s all ease-in .5s;
				}
				
			}
			&.japanese{
				&::before{
					transition:.2s all ease-in .2s;
				}				
			}
			&::before{
				content: '';
				width: 100%;
				height: 100%;
				background: #FFF;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}
	&:not(.point-page) p{
		opacity: 0;
		transition:.2s all ease-in 1.7s;
		line-height: 2em;
	}
	.comment{
		border:2px solid $main-color;
		padding: 25px;
		opacity:0;
		margin:20px auto 40px;
		width: 90%;
		li{
			padding-left: 30px;
			text-align: left;
			position: relative;
			margin-bottom: 15px;
			&:before{
				content:"";
				width: 18px;
				height:18px;
				background: #000;
				display: inline-block;
				position: absolute;
				top:0;
				left:0;
				border-radius:15px;
			}
		}
	}
	.title{
		font-weight: bold;
		font-size: 2rem;
		margin-bottom: 1rem;
	}
	.quote{
		font-size: 140%;
		font-weight: bold;
		position: relative;
		padding: 0 40px;

		&:before{
			content:" “ ";
			color:#666;
			font-family: brandon-grotesque, sans-serif;
			font-weight: 400;
			font-style: normal;
			position: absolute;
			font-size: 200%;
			top:0;
			left:0;
		}
		&:after{
			content:" ” ";
			color:#666;
			font-family: brandon-grotesque, sans-serif;
			font-weight: 400;
			font-style: normal;
			position: absolute;
			font-size: 200%;
			top:0;
			right:0;
		}

	}

	&.is-show{
		.page-title{
			&::before{
				left: 0;
			}
			span{
				&::before{
					top: 100%;
				}
			}
		}
		p{
			opacity: 1;
		}
		ul.comment{
			opacity:1;
			transition:.2s all ease-in 1.7s;
		}
	}
}

.point-page{
	position: relative;
	padding: 50px 0 ;
	overflow: hidden;
	max-width: 100%;
	margin-top: 0;
	.page-title{
		margin-bottom: 0;
		span{
			color: #FFF;
			&.english{
			
			}
			&::before{
				content: '';
				width: 100%;
				height: 100%;
				background: $main-color;
				position: absolute;
				top: 0;
				left: 0;
				
			}
		}
		&::before{
			display: none;
		}
	}
	p{
		line-height: 2em;
		color: #FFF;
		opacity: 0;
		transition:.2s all ease-in 1s;
		max-width: 1000px;
		margin:  0 auto;
	}
	&::before{
		content: '';
		width: 0;
		height:  0;
		border: 0 solid #FFF;
		border-radius: 50%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform:translateX(-50%) translateY(-50%);
		transition:1.8s all ease-in 1.5s;
	}
	&.is-show{
		&::before{
			width: 110vw;
			height: 110vw;
			border: 2px solid #FFF;
		}
	}
}



h4.contents-title{
	color: $main-color;
	text-align: center;
	position: relative;
	margin-bottom: 30px; 
		
	&.contents-title__circle{
		margin-top: 100px;
		
	}
	&::before{
		content: '';
		width: 0;
		height: 0;
		position: absolute;
		border: 1px solid $main-color;
		border-radius: 50%;
		left: 50%;
		transform:translateX(-50%);
		top: 5vw;
		transition:.5s all ease-in .2s;
		opacity: 0;
		
	}
	span{
		font-size: 2vw;
		font-weight: bold;		
		display: block;
		&.english{
			font-size: 0.8em;
			margin-top: 10px;
			font-family: brandon-grotesque, sans-serif;
			font-style: oblique; 
		}
		&.japanese-mini{
			font-size: 0.8em;
		}
	}
	&.is-show{
		&::before{
			width: 20vw;
			height: 20vw;
			opacity: 1;
			top: -5vw;
		}
	}
}

h4.circle-title{
	font-weight: bold;
	font-size: 2.4rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	padding-left: 1.5em;
	margin: 100px 0 50px;
	&::before{
		content: '';
		width: 0;
		height: 0;
		background: $main-color;
		position: absolute;
		left: .5em;
		transition:.2s all ease-in .1s;
		border-radius: 50%;
		
	}
	&::after{
		content: '';
		width: 0;
		height: 0;
		border: 1px solid $main-color;
		position: absolute;
		left: .5em;
		transition:.4s all ease-in .1s;
		border-radius: 50%;
		opacity: 0;
		top: 50%;
		transform: translateY(-50%);
		-webkit-transform: translate;
		
	}
	span{
		color: $main-color;
		font-weight: bold;
		letter-spacing: 0.5em;
		font-family: brandon-grotesque, sans-serif;
		font-style: oblique; 
		
	}
	&.is-show{
		&::before{
			width: 1em;
			height: 1em;
			left: 0;
		}
		&::after{
			width: 4em;
			height: 4em;
			left: -1.5em;
			opacity: 1;
		}
	}
}

.lead-text{
	dt{
		font-weight: bold;
		font-size: 2.4rem;
		margin-bottom: 1.5rem;
		color: $main-color;
		&.center{
			text-align: center;
		}
	}
	dd{
		line-height: 2em;
		text-align: justify;
		& + dd{
			margin-top:2em;
		}
		em{
			font-style: normal;
			font-weight: bold;
		}
	}
	p{
		font-weight: bold;
		font-size: 2.4rem;
		margin-bottom: 1.5rem;
		color: $main-color;
		&.center{
			text-align: center;
		}
	}
}

h5.bar-title{
	background:  $main-color;
	color: #FFF;
	padding: 5px;
	font-size: 1.8rem;
	margin-bottom: 20px;
	&.center{
		text-align: center;
	}
	
}

h5.underline-title{
	font-size: 2rem;
	font-weight: bold;
	border-bottom: 1px solid $main-color;
	margin-bottom: 20px;
	padding-bottom: 10px;
	&.center{
		text-align: center;
	}
	&.main-color{
		color: $main-color;
	}
}

h6.underline-title_min{
	display: flex;
	margin: 50px 0 30px;
	color: $main-color;
	-webkit-flex-direction: column;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	span{
	color: $main-color;
	font-size: 1.6rem;
	padding-bottom: 5px;
	display: inline-block;
	border-bottom: 2px solid $main-color;
	font-weight: bold;
	margin: 0 auto 0 0 ;
	}
	&.center{
		span{
			margin: 0 auto ;
		}
	}
}
.business_area h6.underline-title_min{
	display: flex;
	margin: 50px 0 30px;
	color: $main-color;
	-webkit-flex-direction: column;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	span{
	color: $main-color;
	font-size: 2.4rem;
	padding-bottom: 5px;
	display: inline-block;
	border-bottom: 2px solid $main-color;
	font-weight: bold;
	margin: 0 auto 0 0 ;
	&.yen{
		font-size: 2.4rem;
		font-weight: bold;
		border-bottom: none;
		padding-top: 5px;
	}
	}
	&.center{
		span{
			margin: 0 auto ;
		}
	}
}


.paragraph{
	line-height: 2em;
	max-width: 1000px;
	margin: 0 auto 50px;
	img{
		width: 100%;
		display: block;
	}
	span.poian{
		font-weight: bold;
		color: $main-color;
	}
	.personal_information & .name{
		text-align: right;
		display: block;
	}
	&.mb20{
		margin-bottom: 20px;
	}
}
.attention{
	font-size: 1.2rem;
	line-height: 2em;
	display: block;
}

.notes{
	padding-left: 2em;
	font-size: 1.25rem;
	position: relative;
	&::before{
		content: "※";
		position: absolute;
		top: 0;
		left: .7em;
	}
}

.icon-list{
	li{
		position: relative;
		padding-left: 1.2em;
		margin-bottom: 10px;
		&::before{
			content: '';
			width: 0.8em;
			height: 0.8em;
			background: $main-color;
			position: absolute;
			top: 0.3em;
			left: 0;
		}
	}
	&.black{
		li{
			&::before{
				background: #000;
			}
		}
	}
	&.circle{
		li{
			&::before{
				border-radius: 50%;
			}
		}
	}
}
.content-lead__block{
	background: -moz-linear-gradient(left, $main-color 85%, #135b50);
	background: -webkit-linear-gradient(left, $main-color 85%, #135b50);
	background: linear-gradient(to right, $main-color 85%, #135b50);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 50px 0;
	.main-lead-text{
		width: 100%;
		color: #FFF;
		font-size: 2.4rem;
		letter-spacing: 0.4em;
		line-height: 1;
		text-align: center;
		&+.lead-text{
			margin-top: 30px;
		}
	}
	.lead-text{
		width: 80%;
		color: #FFF;
		font-size: 1.8rem;
		line-height: 1.9;
	}
	&.white{
		background: none;
			.main-lead-text{
			color: $main-color;
			line-height: 2em;
		}
	}
	&.left{
		.main-lead-text{
		text-align-last: left;
		}
	}
}






div.lead-text_ph-section{
	width: 100%;
	margin: 0 auto 50px;
	max-width: 1000px;
	display: flex;
	.lead-text__box{
		width: 60%;
		padding-top: 8%;
		z-index: 2;
		.lead-text{
			background: rgba(255 , 255 , 255 , .8);
			padding: 5%;
			box-shadow: 0 1px 2px rgba(0,0,0,.2);
			min-height: 450px;
		}
	}
	.lead-text__box02{
		width: 100%;
		padding-top: 8%;
		margin-bottom: 50px;
	}
	.ph__box{
		width: 75%;
		padding-bottom: 5%;
		margin-left: -35%;
		display: flex;
		max-height: 500px;
		p{
			// background: #999999;
			width: 100%;
			img{
				width: 100%;
			}
		}
	}
	&:nth-of-type(2n){
		.lead-text__box{
			order: 2;
		}
		.ph__box{
			order: 1;
			margin-left: 0;
			margin-right: -35%;
		}
	}
}
.color-bar{
	position: relative;
	&::before{
		content: '';
		width: 50vw;
		height: 250px;
		background: rgba(#ffb94a , .5);
		position: absolute;
		left: 0;
		top: 50px;
		z-index: -1;
	}
	&.right{
		&::before{
			left: auto;
			right: 0;
		}
	}
	.school-life &{
		&::before{
			background: rgba($main-color , .5);
		}
	}
}
.text-ph-content{
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 50px;
	
	.text-content{
		width: 55%;
		line-height: 2em;
		text-align: justify;
		.bold{

			font-weight: bold;
			font-size: 120%;
			margin-bottom: 5%;
		}
	}
	.ph-content{
		width: 40%;
		margin: 20px auto;
		img{
			width: 100%;
		}
	}
}




.ph-caption-box{
	p{
		margin-bottom: 20px;
		width: 100%;
		img{
			width: 100%;
		}
	}
	dl{
		dt{
			font-weight: bold;
			font-size: 1.8rem;
			margin-bottom: 1rem;
		}
	}
}



.circle-list{
//	margin-bottom: 30px;
	li{
		display: table;
		margin-bottom: 1rem;
		&>p{
			display: table-cell;
			&:first-of-type{
				width: 2rem;
				font-size: 1.3rem;
				color: $main-color;
			}
			.attention{
				display:inline-block;
			}
		}
		a{
			text-decoration: underline;
		}
	}
	&.half{
		display: flex;
		-webkit-flex-wrap: wrap;
		    -ms-flex-wrap: wrap;
		        flex-wrap: wrap;
		li{
			width: 50%;
			text-align: left;
		}
	}
}




ul.list-number{
	margin-bottom: 50px;
	li{
		margin-bottom: 20px;
		text-indent: -1em;
		padding-left:1em;		
		p{
			display: block;
			font-weight: bold;
			color: $main-color;
		}
	}
}


.paragraph{
	a{
		text-decoration: underline;
	}
}

a.circle-link_btn{
	display: inline-flex;
	align-items: center;
	font-size: 1.8rem;
	font-weight: bold;
	position: relative;
	&::before{
		content: '';
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background: $main-color;
		margin-right: 10px;
		transition: all 0.2s ease-in-out;
		
	}
	.reunion &{
		margin-top: 30px;
	}
	&::after{
		content: '';
		width: 10px;
		height: 10px;
		border-top: 1px solid #FFF;
		border-right: 1px solid #FFF;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		position: absolute;
		left: 14px;
		top: 15px;
		transition: all 0.2s ease-in-out;
	}
	&:hover{
		opacity: 1;
		color: $main-color;
		&::before{
			background: #FFF;
			border: 2px solid $main-color;
		}
		&::after{
			border-top: 1px solid $main-color;
			border-right: 1px solid $main-color;
		}
	}
}


/*関連リンク*/
a.link_btn {
	display:flex;
	align-items:center;

	text-align: left;

	width:100%;
	min-height:60px;
	padding:0 100px 0 40px;
	background: #FFF;
	margin:30px 0 20px;
	position:relative;
	color: $main-color;
	text-decoration:underline;
	font-size:1.6rem;
	// font-weight: 600;
	box-shadow: 0px 2px 4px rgba(0,0,0,0.18);
	
	&::after {
		content:'';
		display:block;
		width:65px;
		height:100%;
		background: url(../images/common/link_btn_icon.png) no-repeat center center $main-color;
		position:absolute;
		top:0px;
		right:0px;
	}
}

a[target="_blank"].link_btn{
	&::after {
		content:'';
		display:block;
		width:65px;
		height:100%;
		background: url(../images/common/link_btn_icon_window.png) no-repeat center center $main-color;
		position:absolute;
		top:0px;
		right:0px;
	}
	
}
a[href$=".pdf"].link_btn{
	&::after {
		content:'';
		display:block;
		width:65px;
		height:100%;
		background: url(../images/common/link_btn_icon_pdf.png) no-repeat center center $main-color;
		position:absolute;
		top:0px;
		right:0px;
	}
}
a[href$=".mp3"].link_btn{
	&::after {
		content:'';
		display:block;
		width:65px;
		height:100%;
		background: url(../images/common/link_btn_icon_song.png) no-repeat center center $main-color;
		position:absolute;
		top:0px;
		right:0px;
		
	}
}



.two-column{
	display: flex;
	flex-wrap: wrap;
	margin: 50px auto;
	& > *{
		width: 48%;
		margin-right: 4%;
		margin-left: 0;
		text-indent: 0;
		&:nth-of-type(2n){
			margin-right: 0;
		}
		&:nth-child(2n){
			margin-right: 0;
		}
	}
}
.three-column{
	display: flex;
	flex-wrap: wrap;
	& > *{
		width: 32%;
		margin-right: 2%;
		margin-left: 0;
		&:nth-of-type(3n){
			margin-right: 0;
		}
	}
}



table.table_std {
	border-collapse:collapse;
	width:100%;
	margin-bottom: 20px;
	border-top: 3px solid $main-color;
	border-bottom: 3px solid $main-color;
	border-right: 1px solid $main-color;
	border-left: 1px solid $main-color;
	
	th,td {
		padding:10px;
		font-size:14px;
		color:#000;
		text-align:left;
		vertical-align:middle;
		&.vab{
			vertical-align:baseline;
		}
	}
	td{
		border-bottom:1px solid $main-color;
		border-right:1px solid $main-color;
		background: #FFF;
		&.green{
			background: $main-color;
			color: #FFF;
		}
	}
	th{
		text-align: center;
		border-bottom:1px solid $main-color;
		border-right:1px solid $main-color;
		font-weight: bold;
		background: #74cac5 ;
		color:#FFF;
		
		&.pastel{
			background: rgba($main-color, 0.7) ;
			&.tal{
				text-align: left;
				padding-left: 20px;
			}
			
		}
	}
	.center{
		text-align: center;
	}
}

/*縦書き__settings*/
.vertical-writing {
	line-height: 1.8;
	letter-spacing: 0.03em;
	-webkit-writing-mode: vertical-rl;
	-ms-writing-mode: tb-rl;
	writing-mode: vertical-rl;
}
.vertical-writing p {
	text-indent: 1em;
	margin-left: 10px;
	padding-bottom:10px;
}
.vertical-writing ruby {
	font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Yu Gothic", YuGothic, Verdana, Meiryo, "M+ 1p", sans-serif;
	
}


/* 数字リスト */

.num__list{
	list-style-position: outside;
	margin-left: 2em;
}

/* imagemap */
area{
  border:none;
  outline:none;
}

/* modal */
.modal__box{
	dt{
		font-size: 2.8rem;
		color: $main-color;
		display: block;
		margin-bottom: 30px;
		text-align: center;
		border-bottom: 1px solid;
		padding-bottom: 5px;
		margin-bottom: 10px;
		&.green{
			color: #8bc53f;
		}
		&.yellow{
			color: #f19500;
		}
		.about &{
			color: #FFF;
		}
		span{
			font-size: 2.2rem;
		}
	}
	dd{
		line-height: 2em;
	}
}
.company .modal__box > dt{
	font-size: 5rem;
}
.company .modal__box > dt > span{
	font-size: 1.2rem;
	display: block;
}
.fadein {
  opacity: 0;
}
.fadein.is-active {
  opacity: 1;
 /* transition: all ease 0.65s;*/
  animation: copy_anime .7s both;
}
@keyframes copy_anime {
    0% {
	opacity:0;
	transform: translateY(30px);
    }
    10% {
	opacity:0;
    }
    100% {
	transform: translateY(0);
    }
}






/* project, person 共通 */

.person-intro__box{
	display: flex;
	// height: 30vh;
	min-height: 30vh;
	dl{
		width: 50%;
		// padding: 7vh 6vh 6vh;
		padding: 6vh;
		position: relative;
		background: $main-color;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		// justify-content: space-between;
		color: #FFF;
		-webkit-justify-content: center;
		        justify-content: center;

		&::before{
			content:"各CSSでここに英語入れる";
			position: absolute;
			left: -0.2rem;
			top: -0.3rem;
			font-size: 3rem;
			font-weight: bold;
			line-height: 1;
			.general_manager &{
				content: none;
			}
		}
		dt{
			order: 2;
			display: flex;
			flex-direction: column;
			span{
				font-size: 1.2rem;
				&:first-of-type{
					font-size: 3.5rem;
					font-family: serif;
					letter-spacing: 0.2em;
				}
			}
		}
		dd{
			order: 1;
			font-size: 1.8rem;
			line-height: 1.8;
		}
	}
	.person__photo{
		width: 50%;
		background: #CCC;/* 各CSSで画像を入れる */
		// background: url() no-repeat center center;
		// background-size: cover;
	}
	&.photo-left{
		dl{
			order: 2;
		}
		.person__photo{
			order: 1;
		}
	}
}



/* リンク無効化 グレーアウト */
a.unclickable-link{
	position: relative;
	cursor: default;
	pointer-events: none;
	&:hover{
		opacity: 1;
	}
	&::after{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.3);
		z-index:2;
	}
}
.unclickable-box{
	position: relative;
	a{
		cursor: default;
		pointer-events: none;
		&:hover{
			opacity: 1;
		}
	}
	&::after{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.3);
		z-index:2;
	}
}





@import "module-res";
