@media screen and  (max-width: 1024px ) and (orientation: portrait){


.hader-contener{
	// position: relative;
	// display: flex;
	// align-items: center;
	// -webkit-justify-content: flex-end;
	//         justify-content: flex-end;
	// z-index:1000;
	// top:0;
	// left:0;
	// width: 100vw;
	// height:80px;
	// padding: 20px 50px;
	// background: #FFF;
	// .header-logo-box{
	// 	position: absolute;
	// 	left:2vw;
	// 	top:50%;
	// 	transform:translateY(-50%);
	// 	z-index: 1;
	// 	a.header-rogo{
	// 		display: block;
	// 		height: 40px;
	// 		width: 40px;
	// 		img{
	// 			height: 100%;
	// 		}
	// 	}
	// }
	// .hader-round-btn{
	// 	height: 60px;
	// 	border-radius:80px;
	// 	border: 2px solid $main-color;
	// 	width: 12vw;
	// 	-webkit-justify-content: center;
	// 	justify-content: center;
	// 	margin-left: 15px;
	// 	z-index:1;
	// 	padding: 0 15px;
	// 	a{
	// 		width: 100%;
	// 		height: 100%;
	// 		display: flex;
	// 		-webkit-justify-content: center;
	// 		justify-content: center;
	// 		-webkit-align-items: center;
	// 		align-items: center;
	// 	}
	// 	p.roundbtntext{
	// 		display: inline-block;
	// 		width: fit-content;
	// 		font-size: 1.4rem;
	// 		color: $main-color;
	// 		font-weight: 600;
	// 		line-height: 1.2;
	// 	}
	// }
	// .menu-trigger{
	// 	background: $main-color;
	// 	z-index: 500;
	// 	display: flex;
	// 	justify-content: center;
	// 	align-items: center;
	// 	cursor: pointer;
	// 	padding: 0 5px;
	// 	p.triggerber{
	// 		width: 20px;
	// 		height:  15px;
	// 		display: flex;
	// 		margin-right:  10px;
	// 		flex-direction: column;
	// 		justify-content: space-between;
	// 		span{
	// 			display: block;
	// 			width: 100%;
	// 			height: 2px;
	// 			background: #FFF;
	// 		}
	// 	}
	// 	p.roundbtntext{
	// 		color: #FFF;
	// 	}
	// 	&.is-show{
	// 		border: 2px solid #fff;
	// 	}
	// }
	.mega-menu{
		overflow-y: scroll;
		// position: fixed;
		// top:0;
		// left:0;
		// width: 100vw;
		// height: 100vh;
		// width: 100%;
		// z-index: 450;
		// display: none;
		padding: 80px 0 0;
		// overflow: hidden;
		&::after{
			z-index: -1;
			position: absolute;
			content:'';
			top:0;
			right:0;
			width: 0vw;
			height: 0vw;
			background: $main-color;
			// animation: mega-bg 2s forwards;
			transition:.3s all ease 0s;
		}
		&.is-show{
			display: block;
			&::after{
				width: 100vw;
				height: 100vw;
				z-index: 1;
				 animation: mega-bg .5s forwards;
			}
			ul.megalink-list{
				animation: mega-menu .5s forwards .5s;
			}
		}
		ul.megalink-list{
			width: 100%;
			height: auto;
			display: flex;
			flex-wrap: wrap;
			z-index: 10;
			transition:.3s all ease;
			opacity: 0;
			padding: 0 5vw 5vw;
			background: $main-color;
			&:nth-of-type(2){
				padding-bottom: 80px;
			}
			li{
				width: 48%;
				margin-bottom: 20px;
				.megalist-img{
					width: 100%;
					height: 15vh;
					margin-bottom: 10px;
					background-size: cover;
					img{
						width: 100%;
					}
				}
				// &:nth-of-type(4){
				// 	margin-right: 0;
				// }
				// .megalist-img{
				// 	width: 100%;
				// 	height: 15vh;
				// 	margin-bottom: 10px;
				// 	background-size: cover;
				// 	img{
				// 		width: 100%;
				// 	}
				// }
				// p.megalink-title{
				// 	display: block;
				// 	width: 100%;
				// 	position: relative;
				// 	margin-bottom: 6px;
				// 	padding-bottom: 1px;
				// 	span.en{
				// 		display: block;
				// 		width: 100%;
				// 		font-size: 2.3vw;
				// 		letter-spacing: 0;
				// 		font-weight: 600;
				// 		color: #FFF;
				// 		line-height: 1;
				// 		margin-bottom: 2vh;
				// 	}
				// 	span.jp{
				// 		display: block;
				// 		width: 100%;
				// 		font-size: 1.5rem;
				// 		font-weight: 600;
				// 		color: #FFF;
				// 		margin-bottom: 8px;
				// 		margin-top: -13px;
				// 	}
				// 	&::before{
				// 		position: absolute;
				// 		content:'';
				// 		width: calc(100% - 20px);
				// 		height: 2px;
				// 		bottom:0;
				// 		left:0;
				// 		background: #FFF;
				// 	}
				// 	&::after{
				// 		position: absolute;
				// 		content:'＞';
				// 		right:0;
				// 		bottom:0;
				// 		font-size: 2rem;
				// 		font-weight: 600;
				// 		color: #FFF;
				// 		transform:translateY(50%) scaleX(.6);
				// 	}
				// }
				// ul.megasublink-list{
				// 	display: block;
				// 	width: 100%;
				// 	li{
				// 		width: 100%;
				// 		margin-bottom: -.5vh;
				// 		background: none;
				// 		a{
				// 			display: block;
				// 			width: 100%;
				// 			color: #FFF;
				// 			font-size: 2.2vh;
				// 			font-weight: 600;
				// 		}
				// 	}
				// }
				// .menu00{
				// 	background: url("../images/mainvisual/mainvisual_concept.jpg")no-repeat center center;
				// 	background-size: cover;
				// }
				// .menu01{
				// 	background: url("../images/mainvisual/mainvisual_a-1.jpg")no-repeat center center;
				// 	background-size: cover;
				// }
				// .menu02{
				// 	background: url("../images/mainvisual/mainvisual_b-7.jpg")no-repeat center center;
				// 	background-size: cover;
				// }
				// .menu03{
				// 	background: url("../images/mainvisual/mainvisual_c-menu.jpg")no-repeat top center;
				// 	background-size: cover;
				// }
				// .menu04{
				// 	background: url("../images/mainvisual/mainvisual_c-1.jpg")no-repeat center center;
				// 	background-size: cover;
				// }

				// .menu05{
				// 	background: url("../images/mainvisual/mainvisual_e-1.jpg")no-repeat bottom center;
				// 	background-size: cover;
				// }
				// .menu06{
				// 	background: url("../images/mainvisual/mainvisual_f.jpg")no-repeat center center;
				// 	background-size: cover;
				// }
				// .menu07{
				// 	background: url("../images/mainvisual/mainvisual_f-1.jpg")no-repeat center center;
				// 	background-size: cover;
				// }
				// .menu08{
				// 	background: url("../images/mainvisual/mainvisual_c-1_2.jpg")no-repeat center center;
				// 	background-size: cover;
				// }
			}
		}
	}
}
}


@media screen and (max-width: 834px) {


/**********************

HEADER_SETTINGS

***********************/

body{
	padding-top: 65px;
}


//---------------------------------------2022renew




.hader-contener{
	// position: relative;
	// display: flex;
	// align-items: center;
	// -webkit-justify-content: flex-end;
	//         justify-content: flex-end;
	// width: 100vw;
	height:65px;
	// padding: 20px 50px;
	// background: #FFF;
	.header-logo-box{
		position: absolute;
		left:2vw;
		top:50%;
		transform:translateY(-50%);
		z-index: 1;
		a.header-rogo{
			display: block;
			height: auto;
			width: 77vw;
			margin-left: 5px;
			img{
				height: auto;
				width: 100%;
				display: block;
			}
		}
	}
	.hader-round-btn{
		// height: 40px;
		border-radius:0px;
		// border: 2px solid $main-color;
		// width: 200px;
		// -webkit-justify-content: center;
		// justify-content: center;
		// margin-left: 15px;
		// z-index:1;
		position: absolute;
		border:0px;
		&:nth-of-type(2){
			width: 50%;
			margin: 0 0 0 0;
			left:0;
			top:100%;
			background: rgba(#fff , .7);
			border-right:1px solid $main-color;
		}
		&:nth-of-type(3){
			width: 50%;
			margin: 0 0 0 0;
			left:50%;
			top:100%;
			background: rgba(#fff , .7);
		}
		&:nth-of-type(4){
			width: 65px;
			height: 65px;
			margin: 0 0 0 0;
			right:0;
			top:0;
		}
		a{
			// width: 100%;
			// height: 100%;
			// display: flex;
			// -webkit-justify-content: center;
			// justify-content: center;
			// -webkit-align-items: center;
			// align-items: center;
		}
		p.roundbtntext{
			// display: inline-block;
			// width: fit-content;
			// font-size: 1.4rem;
			// color: $main-color;
			// font-weight: 600;
		}
	}
	.menu-trigger{
		background: $main-color;
		z-index: 500;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		cursor: pointer;
		p.triggerber{
			width: 20px;
			height:  15px;
			display: flex;
			margin-right:  0px;
			margin-bottom: 5px;
			flex-direction: column;
			justify-content: space-between;
			span{
				display: block;
				width: 100%;
				height: 2px;
				background: #FFF;
			}
		}
		p.roundbtntext{
			color: #FFF;
			font-size: 1rem;
		}
		&.is-show{
			border: 2px solid #fff;
		}
	}


	.header-circle-btn{
		width: 50%;
		height: 40px;
		border-radius: unset;
		border: 0;
		// display: flex;
		// align-items: center;
		// justify-content: center;
		margin-left: 0;
		z-index:1;
		padding: 0;
		position: absolute;
		background: rgba(#fff , .8);
		right: 0;
		&::after{
			position: absolute;
			content: none;
			background: url("../images/common/arrow_02.svg")no-repeat center center;
			-webkit-background-size: contain;
			     -o-background-size: contain;
			        background-size: contain;
			bottom: 5px;
			left: 0;
			right: 0;
			margin: auto;
			width: 14px;
			height: 14px;
		}
		&.past{
			border-bottom: 1px solid $main-color;
			color: $main-color;
			background: rgba(#FFF, 0.6);
			a{
				background: rgba($main-color, 0.4);
			}
		}
		a{
			// width: 100%;
			height: 40px;
			// display: flex;
			flex-direction: row;
			// align-items: center;
			// justify-content: center;
			margin-bottom: 0;
			background: rgba($main-color, 0.2);
			span{
				display: inline-block;
				width: fit-content;
				color: $main-color;
				font-size: 1.3rem;
				letter-spacing: 0.05em;
				font-weight: 600;
				line-height: 1.2;
				& + span{
					margin: 0 0 0 5px ;
				}
			}
		}
		&:nth-child(2){
			left:0;
			top:65px;
			border-right:1px solid $main-color;
			.ver02 &,.ver03 &{
				left:auto;
			}
		}
		&:nth-child(3){
			left:50%;
			top:65px;
		}
		&:nth-child(4){
			left:0;
			top:105px;
			border-right:1px solid $main-color;
		}
		&:nth-child(5){
			left:50%;
			top:105px;
		}
		&.menu-trigger{
			width: 65px;
			height: 65px;
			right:0!important;
			top:0;

			// background: $main-color;
			// z-index: 500;
			// display: flex;
			// flex-direction: column;
			// justify-content: center;
			// align-items: center;
			// cursor: pointer;
			// padding: 0 5px;
			// p.trigger-bar{
			// 	width: 20px;
			// 	height: 15px;
			// 	display: flex;
			// 	flex-direction: column;
			// 	justify-content: space-between;
			// 	span{
			// 		display: block;
			// 		width: 100%;
			// 		height: 2px;
			// 		background: #FFF;
			// 	}
			// }
			 p.menu-trigger-txt{
			// 	margin-top: 8px;
			// 	color: #FFF;
			 	font-size: 1.2rem;
			// 	line-height: 1;
			 }
			// &.is-show{
			// 	border: 2px solid #fff;
			// }
		}
	}



	.mega-menu{
		// position: fixed;
		// top:0;
		// left:0;
		// width: 100vw;
		// height: 100vh;
		// width: 100%;
		// z-index: 450;
		// display: none;
		padding: 80px 0vw 0px;
		// overflow: hidden;
		overflow-y: scroll;
		&::-webkit-scrollbar{
			display: none;
		}
		&::after{
			z-index: -1;
			position: absolute;
			content:'';
			top:0;
			right:0;
			width: 0vw;
			height: 0vw;
			background: $main-color;
			// animation: mega-bg 2s forwards;
			transition:.3s all ease 0s;
		}
		&.is-show{
			display: block;
			&::after{
				width: 100vw;
				min-height: 100vw;
				height: 100%;
				z-index: 1;
				 animation: mega-bg .5s forwards;
			}
			ul.megalink-list{
				animation: mega-menu .5s forwards .5s;
			}
		}
		ul.megalink-list{
			width: 100%;
			height: auto;
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			z-index: 10;
			transition:.3s all ease;
			opacity: 0;
			padding: 0 5vw 5vw;
			background: $main-color;
			&:nth-of-type(2){
				padding-bottom: 80px;
			}
			li{
				width: 100%;
				margin-bottom: 20px;
				.megalist-img{
					width: 100%;
					height: 15vh;
					margin-bottom: 10px;
					background-size: cover;
					img{
						width: 100%;
					}
				}




				p.megalink-title{
					display: flex;
			        flex-direction: column-reverse;
					width: 100%;
					position: relative;
					margin-bottom: 10px;
					padding-bottom: 1px;
					span.en{
						display: block;
						width: 100%;
						font-size: 2.3vw;
						letter-spacing: 0;
						font-weight: 600;
						color: #FFF;
						line-height: 1;
						margin-bottom: 8px;
						margin-top: 0px;
					}
					span.jp{
						display: block;
						width: 100%;
						font-size: 1.5rem;
						font-weight: 600;
						color: #FFF;
						margin-bottom: 0vh;
						margin-top: 0px;
					}
					&::before{
						position: absolute;
						content:'';
						width: calc(100% - 20px);
						height: 2px;
						bottom:0;
						left:0;
						background: #FFF;
					}
					&::after{
						position: absolute;
						content:'＞';
						right:0;
						bottom:0;
						font-size: 2rem;
						font-weight: 600;
						color: #FFF;
						transform:translateY(50%) scaleX(.6);
					}
				}
				ul.megasublink-list{
					display: block;
					width: 100%;
					li{
						width: 100%;
						margin-bottom: .3vh;
						background: none;
						a{
							display: block;
							width: 100%;
							color: #FFF;
							font-size: 1.8vh;
							font-weight: 600;
						}
					}
				}
				.menu00{
					background: url("../images/mainvisual/mainvisual_concept.jpg")no-repeat center center;
					background-size: cover;
				}
				.menu01{
					background: url("../images/mainvisual/mainvisual_a-1.jpg")no-repeat center center;
					background-size: cover;
				}
				.menu02{
					background: url("../images/mainvisual/mainvisual_b-7.jpg")no-repeat center center;
					background-size: cover;
				}
				.menu03{
					background: url("../images/mainvisual/mainvisual_c-menu.jpg")no-repeat top center;
					background-size: cover;
				}
				.menu04{
					background: url("../images/mainvisual/mainvisual_c-1.jpg")no-repeat center center;
					background-size: cover;
				}

				.menu05{
					background: url("../images/mainvisual/mainvisual_e-1.jpg")no-repeat bottom center;
					background-size: cover;
				}
				.menu06{
					background: url("../images/mainvisual/mainvisual_f.jpg")no-repeat center center;
					background-size: cover;
				}
				.menu07{
					background: url("../images/mainvisual/mainvisual_f-1.jpg")no-repeat center center;
					background-size: cover;
				}
				.menu08{
					background: url("../images/mainvisual/mainvisual_c-1_2.jpg")no-repeat center center;
					background-size: cover;
				}
			}
		}
	}
}




.btn-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	background: $main-color;
	// border-bottom: 1px solid #FFF;
	box-shadow: 0px 2px 4px rgba(0,0,0,0.18);
	transition: .6s all ease ;
	position: relative;
	z-index: -1;
	&.hide-out {
		transform:translateY(-120px);
		transition: .6s all ease ;
	}
	.header-entry-btn{
		width: 50%;
		height: 50px;
		border-right: 1px solid #FFF;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		background: rgba(#FFF, 0.2);
		&.past{
			background: rgba(#FFF, 0.1);
		}
		&::after{
			content: '';
			position: absolute;
			right: 16px;
			top: 0;
			bottom: 0;
			margin: auto;
			width: 10px;
			height: 10px;
			border-top: 1px solid #FFF;
			border-right: 1px solid #FFF;
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
		}
		&:first-child{
			border-left:  none;
			border-bottom: 1px solid #FFF;
		}
		&:nth-child(2){
			border-right: none;
			border-bottom: 1px solid #FFF;
		}
		&:nth-child(3){
		}
		&:last-child{
			border-right: none;
		}
		a {
			width: 100%;
			color: #FFF;
			text-align: center;
			// padding: 10px ;
			display: block;
			span {
				display: inline-block;
				font-weight: 700;
			font-size: 1.4rem;
			}
		}
		&:hover{
			background: #FFF;
			transition: .4s all ease ;
			&::after{
				content: '';
				position: absolute;
				right: 16px;
				top: 0;
				bottom: 0;
				margin: auto;
				width: 10px;
				height: 10px;
				border-top: 1px solid $main-color;
				border-right: 1px solid $main-color;
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);
			}
			a {
				width: 100%;
				color: $main-color;
				text-align: center;
			// padding: 10px ;
			display: block;
			span {
				display: inline-block;
				font-weight: 700;
				font-size: 1.4rem;
			}
		}
		}
	}
}

.btn-float {
	position: absolute;
	right: 10px;
	top: 100px;
	width: 180px;
	transition: .5s all ease ;
	&.hide-out {
		transform:translateX(190px);
		transition: .8s all ease ;
	}
	.header-entry-btn{
		width: 100%;
		height: 60px;
		margin-bottom: 10px;
		border-radius: 5px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		// background: $main-color;
		border: 1px solid #FFF;
		background: rgba($main-color, 0.6);
		&.past{
			background: rgba($main-color, 0.8);
		}
		&::after{
			content: '';
			position: absolute;
			right: 16px;
			top: 0;
			bottom: 0;
			margin: auto;
			width: 8px;
			height: 8px;
			border-top: 2px solid #FFF;
			border-right: 2px solid #FFF;
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
		}
		a {
			width: 100%;
			color: #FFF;
			text-align: center;
			// padding: 10px ;
			display: block;
			span {
				display: block;
				font-weight: 700;
			font-size: 1.4rem;
			}
		}
		&:hover {
			background: #FFF;
			border: 1px solid $main-color;
			transition: .4s all ease ;
			&::after{
				content: '';
				position: absolute;
				right: 16px;
				top: 0;
				bottom: 0;
				margin: auto;
				width: 8px;
				height: 8px;
				border-top: 2px solid $main-color;
				border-right: 2px solid $main-color;
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);
			}
			a {
				width: 100%;
				color: $main-color;
				text-align: center;
			// padding: 10px ;
			display: block;
			span {
				display: block;
				font-weight: 700;
				font-size: 1.4rem;
			}
		}
	}
	}
}















footer{
	.l-footer{
		.fotter-backbtn-box{
			// width: 100%;
			// display: flex;
	  //       justify-content: center;
	  //       margin-bottom: 5vh;
	  //       margin-top: 8vh;
	          flex-wrap: wrap;
	        a{
	        	display: flex;
	        	width: 90px;
	        	height: 90px;
	        	position: relative;
	        	border-radius:50%;
	        	border:2px solid $main-color;
    	         align-items: center;
	        	p{
	        		display: block;
	        		width: 100%;
	        		font-size: 1.3rem;
	        		font-weight: 900;
	        		color: $main-color;
	        		text-align: center;
	        		letter-spacing: 0;
	        		 transform:translateY( 5px);
	        	}
	        	&::after{
	        		content:'＞';
	        		position: absolute;
	        		left: 50%;
	        		top: 16px;
	        		color: $main-color;
	        		font-weight: 600;
	        		transform:translateX(-50%)  rotate(-90deg) scaleX( .7);
	        	}
	        }
		}
		.fotter-entry-box{
			 // display: flex;
			 // width: 100%;
	   //       justify-content:  center;
	   //       margin-bottom: 3vh;
	         flex-wrap: wrap;
	         padding: 0 7vw;
	         a{
	         	width: 100%;
	         	border:2px solid $main-color;
	         	font-size: 1.9rem;
	         	font-weight: 600;
	         	padding: 10px 20px;
	         	 border-radius:40px;
	         	  color: $main-color;
	         	  text-align: center;
	         	  margin-bottom: 15px;
	         	  &.mypage{
	         	  	margin-left: 0px;
	         	  	 background: $main-color;
	         	  	 color: #FFF;
	         	  }
	         }
		}

		.footer-entry-box{
			// width: 100%;
			// display: flex;
			padding: 0 7vw;
			flex-wrap: wrap;
			// justify-content: center;
			// margin-bottom: 3vh;
			a{
				width: 100%;
				// display: inline-block;
				// border: 2px solid $main-color;
				// font-size: 1.8rem;
				// letter-spacing: 0.05em;
				// font-weight: 600;
				padding: 10px;
				// border-radius:40px;
				// color: $main-color;
				// text-align: center;
				margin: 0 0 15px;
				// &.past{
				// 	background: rgba($main-color, 0.1);
				// }
				&:last-child{
					margin: 0;
				}
			}
		}

	}
}




















































//-----------------------------------------------







// body.fixed{
//   position: fixed;
//   width:100%;
// }

 .header{
// 	position: fixed;
// 	display: flex;
// 	align-items: center;
// 	z-index:1000;
// 	top:0;
// 	left:0;
// 		width: 100%;
// 		height:70px;
 		padding: 10px 0 10px 20px;
// 		-webkit-box-pack: justify;
// 		   -ms-flex-pack: justify;
// 		 justify-content: space-between;
// 		 height:70px;
// /*		 background: rgba(0,0,0,.7);*/
// 		 display: flex;
// 		 background: #FFF;
// 	.top-page &{
// 		background: none;
// 	}
}



 .g-navi-logo img{
 	display: block;
 	height:auto;
 	width: calc(100% - 80px);
 }


// .g-navi-menu010 img{
// 	display: block;
// 	height:20px;
// }

.g-navi-btn__container{
	display: none;
// 	display: flex;
// 	text-align: center;
// 	.entry__btn{
// 		background: #FFF;
// 		font-size: 1.5rem;
// 		color: $main-color;
// 		padding: 5px 20px;
// 		margin-right: 20px;
// 		font-weight: bold;
// 		width: 150px;
// 		border: 1px solid $main-color;
// 	}
// 	.mypage__btn{
// 		background: $main-color;
// 		font-size: 1.5rem;
// 		color: #FFF;
// 		padding: 5px 20px;
// 		font-weight: bold;
// 		width: 150px;
// 	}
}


/**********************MENU_SETTINGS***********************/

// .menu-panel{
// 	width: 100%;
// 	background: #FFF;
// 	z-index:1000;
// 	text-align: center;
// 	.inner{
		
// 	}
// }


// .menu-panel img{
// 	height:100%;
// }



.megamenu{
	// background: rgba(166,187,199,.8);
	// background: #FFF;
	// z-index:1500;
	// position: fixed;
	padding-top: 0;
	// top:0;
	// left:0;
	// width: 100%;
	// height:100%;
	// display: none;
}

.menu-panel{
	.inner{
		.content-title{
			// position: static;
			// top:auto;
			// left:auto;
			// transform: translateY(0) translateX(0);
			// -webkit-transform: translateY(0) translateX(0);
			margin: 0;
			dt{
				font-size: 2rem;
				padding-top: 20px;
				margin-bottom: 0;
				// padding-bottom: 20px;
				&:after{
					display: none;
				}
			}
		}
		.menu-panel-layout{
			height:calc(100vh - 125px);
			// display: flex;
			.text{
				.english{
						font-family: 'Prompt', sans-serif;
					font-style: italic;
					font-size: 3.8vw;
				}
				.japanese{
				font-size: 2.4vw;
				}
			}
			// .panel-large{
			// 	width: 0;
			// 	a{
			// 		background: #666;
			// 		display: block;
			// 		position: relative;
			// 		width: 100%;
			// 		padding-top: 100%;
			// 		color:#fff;
			// 		.text{
			// 			position: absolute;
			// 			top: 50%;
			// 			left: 50%;
			// 			transform: translateY(-50%) translateX(-50%);
			// 			-webkit-transform: translateY(-50%) translateX(-50%);
			// 			margin: auto;
			// 		}
			// 	}
			// }
			.panel-list{
				// width: 100%;
				// display: flex;
				// -webkit-flex-wrap: wrap;
				//     -ms-flex-wrap: wrap;
				//         flex-wrap: wrap;
				//         align-items: flex-start;
				a{
					width: 50%!important;
					height:33.333333%!important;
					// position: relative;
					// display:block;
					// background: #666;
					// position: relative;
					// // padding-top: 12.5%;
					// height:300px;
					// color:#fff;
					&.p01{
						background: url("../images/mainvisual/mainvisual_a-1.jpg")no-repeat center center;
						-webkit-background-size: cover;
						     -o-background-size: cover;
						        background-size: cover;
					}
					&.p02{
						background: url("../images/mainvisual/mainvisual_b-1.jpg")no-repeat center center;
						-webkit-background-size: cover;
						     -o-background-size: cover;
						        background-size: cover;
					}
					&.p03{
						background: url("../images/mainvisual/mainvisual_c-1.jpg")no-repeat left center;
						-webkit-background-size: cover;
						     -o-background-size: cover;
						        background-size: cover;
					}
					&.p04{
						background: url("../images/mainvisual/mainvisual_d-1.jpg")no-repeat left center;
						-webkit-background-size: cover;
						     -o-background-size: cover;
						        background-size: cover;
					}
					&.p05{
						background: url("../images/mainvisual/mainvisual_e-1.jpg")no-repeat 90% center;
						-webkit-background-size: cover;
						     -o-background-size: cover;
						        background-size: cover;
					}
					&.p06{
						background: url("../images/mainvisual/mainvisual_f-1.jpg")no-repeat left center;
						-webkit-background-size: cover;
						     -o-background-size: cover;
						        background-size: cover;
					}
					// .text{
					// 	position: absolute;
					// 	top: 50%;
					// 	left: 50%;
					// 	transform: translateY(-50%) translateX(-50%);
					// 	-webkit-transform: translateY(-50%) translateX(-50%);
					// 	margin: auto;
					// }
				}
			}
		}
	}
}


 .menu-search{
// 	width: 60%;
 	margin:0 auto 0;
 }


 .l-menu-trigger{
// 		          position: fixed;
 		          top: 10px;
 		          left: auto;
 		          right:0;
 		          width: 70px;
 		          height:70px;
// 	z-index:3000!important;
// 	transition: all 0.3s ease;
 	transform:translateX(0);
 	-webkit-transform:translateX(0);
// 	margin: auto;
 }
// .l-menu-trigger.is-show{
// 	        transition: all 0.3s ease;
// }
// .menu-trigger,
// .menu-trigger span {
//   display: inline-block;
//   transition: all .4s;
//   box-sizing: border-box;
// }
// .menu-trigger {
// 	right:auto;
// 	transition: all 0.3s ease;
//   width: 60px;
//   height: 60px;
// 	cursor:pointer;
// }
// .is-show .menu-trigger {
// 	position:relative;
// 	position: fixed;
// 	z-index:100000;
// 	top:0;
// 	left:0;
// 	padding-top: 80px;
// 	margin: 0;
// 	transition: all 0.3s ease;
// 	width: 60px;
// 	height: 60px;
// 	cursor:pointer;
// }
// .is-show .menu-trigger:before {
// }
// .menu-trigger span {
// 	position: absolute;
// 	left: 25%;
// 	width: 50%;
// 	height: 1px;
// 	background-color: $main-color;
// }
// .menu-trigger span:nth-of-type(1) {
// 	top: 15px;
// }
// .menu-trigger span:nth-of-type(2) {
// 	top: 25px;
// }
// .menu-trigger::after {
// 	position: absolute;
// 	left: 0;
// 	top: 35px;
// 	content: 'MENU';
// 	display: block;
// 	width: 100%;
// 	color: $main-color;
// 	font-size: 9px;
// 	text-decoration: none;
// 	text-align: center;
// 	white-space: nowrap;
// 	transition: all .4s;
// }
// .is-show .menu-trigger::after {
// 	content: 'CLOSE';
// 	bottom: -25px;
// 	font-size: 10px;
// 	color:$main-color;
// }
// .is-show .menu-trigger span:nth-of-type(1) {
// 	-webkit-transform: translateY(20px) rotate(-45deg);
// 	transform: translateY(20px) rotate(-45deg);
// 	top:0;
// 	background: $main-color;
// }
// .is-show .menu-trigger span:nth-of-type(2) {
// 	-webkit-transform: translateY(-20px) rotate(45deg);
// 	transform: translateY(-20px) rotate(45deg);
// 	top:40px;
// 	background: $main-color;
// }


/*********************************

main-visual

**********************************/


	.mainvisual{
		min-height: 0;
		height: clamp(300px, 28vh, 600px);
		// width: 100vw;
		// height::60vh;
		// position: relative;
		// overflow: hidden;
		padding:100px 0;
		// overflow: hidden;
		// background: #efefef;

		margin-top: 40px;

		// .top-page &{
		// 	height: 100vh;
		// }
		// .project &{
		// 	background: url("../images/mainvisual/mainvisual_a-1.jpg")no-repeat center center;
		// 	-webkit-background-size: cover;
		// 	-o-background-size: cover;
		// 	background-size: cover;
		// }
		.crosstalk01 &{
			background: url("../images/mainvisual/mainvisual_b-2-1.jpg")no-repeat center 10%;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
		}
		.crosstalk02 &{
			background: url("../images/mainvisual/mainvisual_b-2-2.jpg")no-repeat center 5%;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
		}
		.interview01 &{
			background: url("../images/mainvisual/mainvisual_b-3.jpg")no-repeat center 20%;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
		}
		.interview02 &{
			background: url("../images/mainvisual/mainvisual_b-4.jpg")no-repeat 85% 20%;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
		}
		.interview03 &{
			background: url("../images/mainvisual/mainvisual_b-5.jpg")no-repeat 80% 25%;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
		}
		.interview04 &{
			background: url("../images/mainvisual/mainvisual_b-6.jpg")no-repeat 70% 20%;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
		}
		.interview05 &{
			background: url("../images/mainvisual/mainvisual_b-7.jpg")no-repeat 70% bottom;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
		}
		.interview06 &{
			background: url("../images/mainvisual/mainvisual_b-8.jpg")no-repeat 65% bottom;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
		}
		.interview07 &{
			background: url("../images/mainvisual/mainvisual_b-9.jpg")no-repeat 80% bottom;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
		}
		.interview08 &{
			background: url("../images/mainvisual/mainvisual_b-10.jpg")no-repeat 70% 25%;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
		}
		.interview09 &{
			background: url("../images/mainvisual/mainvisual_b-11.jpg")no-repeat 90% 10%;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
		}
		.interview10 &{
			background: url("../images/mainvisual/mainvisual_b-12.jpg")no-repeat 70% 13%;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
		}
		.interview11 &{
			background: url("../images/mainvisual/mainvisual_b-13.jpg")no-repeat 70% 15%;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
		}
		.interview12 &{
			background: url("../images/mainvisual/mainvisual_b-14.jpg")no-repeat 80% 15%;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
		}






		.interview13 &{
			background: url("../images/mainvisual/mainvisual_b-15.jpg")no-repeat 64% 15%;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
		}
		.interview14 &{
			background: url("../images/mainvisual/mainvisual_b-16.jpg")no-repeat 90% 15%;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
		}
		.interview15 &{
			background: url("../images/mainvisual/mainvisual_b-17.jpg")no-repeat 73% 15%;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
		}
		.interview16 &{
			background: url("../images/mainvisual/mainvisual_b-18.jpg")no-repeat 90% 15%;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
		}


		.information &{
			background: url("../images/mainvisual/mainvisual_c-1.jpg")no-repeat left 60%;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
			padding: 88px 0;
			height: 25vh;
		}

		.business &{//現aboutus
			background: url("../images/mainvisual/mainvisual_c-1.jpg")no-repeat left 60%;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
			padding: 88px 0;
			height: 25vh;
		}
		.message &{
			background: url("../images/mainvisual/mainvisual_e-1-1.jpg")no-repeat center top 50%;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
		}
		.aboutus &{//現company
			background: url("../images/mainvisual/mainvisual_d-1.jpg")no-repeat 15% top;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
		}
		.sustainability &{
			background: url("../images/mainvisual/mainvisual_d-1.jpg")no-repeat 15% top;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
		}
		.recruit &{
			background: url("../images/mainvisual/mainvisual_f-1.jpg")no-repeat left center;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
		}

		.survey &{
			background: url("../images/mainvisual/mainvisual_f.jpg")no-repeat center center;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
		}


		.page-title__section{
			// text-align: center;
			// position: absolute;
			top: 60%;
			// left: 50%;
			width: 100%;
			// transform: translateY(-50%) translateX(-50%);
			// -webkit-transform: translateY(-50%) translateX(-50%);
			// margin: auto;
			.page-title__eg{
				font-size: 3.4rem;
				// font-weight: 900;
				// font-family: 'Prompt', sans-serif;
				// font-style: italic;
				// color: #FFF;
				// text-shadow:0 1px 2px rgba(0,0,0,.20);
			}
			.page-title__jp{
				margin-top: 10px !important;
				font-size: 1.4rem;
				// letter-spacing: .5em;
				// font-weight: 900;
				// color: #FFF;
				// text-shadow:0 1px 2px rgba(0,0,0,.20);
			}
		}
	}



	/*********************************

	container-settings

	**********************************/

	.content-inner{
		// width: 1000px;
		// margin: 0 auto;
		width: 100%;
	}

	.content-menu-title__section{
		padding: 10% 5%;
		// position: relative;
		// &.bg-grey{
		// background: $bg-grey;
		// }
	}
	// .content-menu-title__section{
	// 	padding: 10% 5%;
	// 	position: relative;
	// 	&.bg-grey{
	// 	background: $bg-grey;
	// 	}
	// }
	// .content-section{
	// 	padding: 0 5% 10%;
	// 	position: relative;
	// 	&.bg-grey{
	// 	background: $bg-grey;
	// 	}
	// }
	.content-menu__title{
		// text-align: center;
		// position: relative;
		// z-index: 100;
		position: static;
		top: auto;
		left: auto;
		transform: translateY(0) translateX(0);
		-webkit-transform: translateY(0) translateX(0);
		// margin: auto;
		// color:$main-color;
		// text-align: center;
	}
	.content-menu__title dt{
		// position: relative;
		// padding-bottom: 10px;
		// margin-bottom: 20px;
		font-size: 4rem;
		// font-weight: 900;
		// font-family: 'Prompt', sans-serif;
		// font-style: italic;
	}

	// .content-menu__title dt::after{
	// 	content:"";
	// 	width: 50px;
	// 	height: 5px;
	// 	background: $main-color;
	// 	display: inline-block;
	// 	position: absolute;
	// 	bottom: 0;
	// 	left: 50%;
	// 	transform: translateY(-50%);
	// 	-webkit-transform: translateY(-50%);
	// 	margin: auto;
	// 	margin-left: -30px;
	// }

	// .content-title{
	// 	text-align: center;
	// 	position: relative;
	// 	z-index: 100;
	// 	position: absolute;
	// 	top: 40%;
	// 	left: 50%;
	// 	transform: translateY(-50%) translateX(-50%);
	// 	-webkit-transform: translateY(-50%) translateX(-50%);
	// 	margin: auto;
	// 	color:#fff;
	// 	text-align: center;
	// }



	.footer-btn__container {
		// display: flex;
		// font-weight: 900;
		// text-align: center;
		font-size: 1.4rem;
		// box-shadow: 16px 16px 36px rgba(131,161,181,.1);
		// .footer-entry__btn {
		// 	width: 50%;
		// 	padding: 20px 0;
		// 	color: $main-color;
		// 	background: #FFF;
		// }
		// .footer-mypage__btn {
		// 	width: 50%;
		// 	padding: 20px 0;
		// 	color: #FFF;
		// 	background: $main-color;
		// }
	}
	.footer-sitemap__section {
		// background: $bg-grey;
		// padding: 30px 5% 0;
		p {
			width: 80%;
			margin:0 auto 30px;
			// margin-bottom: 30px;
			// img {
			// 	width: 100%;
		}
		.footer-sitemap__container {
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			   -ms-flex-direction: column;
			       flex-direction: column;
			// display: flex;
			// padding-bottom: 100px;
			li {
				width: 100%;
				margin:0 0 5% 0;
				// margin-right: 2%;
				// &:last-child{
				// 	margin-right: 0;
				// }
				// dt {
				// 	color: $main-color;
				// 	border-bottom: 1px solid;
				// 	font-weight: 900;
				// 	padding-bottom: 5px;
				// 	margin-bottom: 5px;
				// }
				// dd {
				// 	a {
				// 		color: $main-color;
				// 	}
				// }
			}
		}
	}
	// .page-top__btn {
	// 	background: $bg-grey;
	// 	text-align: center;
	// 	height: 40px;
	// 	position: relative;
	// 	a {
	// 		width: 200px;
	// 		color: $main-color;
	// 		background: #FFF;
	// 		padding: 10px 20px;
	// 		font-size: 2rem;
	// 		font-weight: bold;
	// 		font-family: 'Prompt', sans-serif;
	// font-style: italic;
	// 		position: absolute;
	// 		bottom: 0;
	// 		right: 0;
	// 		left: 0;
	// 		margin: 0 auto;
	// 	}
	// }
	.footer-copyright__section {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		// box-shadow:0px 9px 3px -3px rgba(131,161,181,.1) inset;
		// display: flex;
		// background: $main-color;
		// color: #FFF;
		padding: 10px 7vw;
		height: auto;
		// -webkit-align-items: center;
		//         align-items: center;
		// -webkit-justify-content: space-between;
		//         justify-content: space-between;
		.footer-links__container {
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-ms-flex-direction: column;
			flex-direction: column;
			width: 100%;
			margin: 20px 0;
			// display: flex;
			// color: #FFF;
			// font-size: 1.5rem;
			li {
				width: 100%;
				border-right: none;
				// border-bottom: 1px solid #fff;
				margin-right: 0;
				padding-right: 0;
				box-shadow: 0px 2px 4px rgba(0,0,0,0.18);
				margin-bottom: 15px;
				border-radius:10px;
				overflow: hidden;
				a {
					color: $main-color;
					display: block;
					display: block;
					background: #FFF;
					padding: 10px;


					position: relative;
					&::after{
						content:"";
						width: 10px;
						height:10px;
						border-right: 1px solid $main-color;
						border-bottom: 1px solid $main-color;
						position: absolute;
						top: 50%;
						right: 20px;
						transform: translateY(-50%) rotate(-45deg);
						-webkit-transform: translateY(-50%) rotate(-45deg);
						margin: auto;
					}
				}
			}
		}
		// .footer-copyright {
		// 	color: #FFF;
		// 	font-size: 1.5rem;
		// }
	}
	.menu-panel__container{
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		// display: flex;
		// padding: 0 5%;
		// -webkit-flex-wrap: wrap;
		// -ms-flex-wrap: wrap;
		// flex-wrap: wrap;
		// -webkit-justify-content: center;
		// justify-content: center;
		a{
			width: 100%;
			background: rgba($main-color,.3);
			color: #FFF;
			// padding: 20px;
			padding: 10px;
			margin-right: 2%;
			// height: 300px;
			height: 25vh;
			display: flex;
			-webkit-flex-direction: column;
			    -ms-flex-direction: column;
			        flex-direction: column;
			-webkit-justify-content: center;
			        justify-content: center;
			margin-bottom: 20px;
			// &:nth-child(3n){
			// 	margin-right: 0;
			// }

			&.menu-panel__project01{
				background: url("../images/mainvisual/mainvisual_a-2.jpg")no-repeat center center;
				-webkit-background-size: cover;
				     -o-background-size: cover;
				        background-size: cover;
				-webkit-justify-content: space-between;
			       		justify-content: space-between;
			}
			&.menu-panel__project02{
				background: url("../images/mainvisual/mainvisual_a-3.jpg")no-repeat center center;
				-webkit-background-size: cover;
				     -o-background-size: cover;
				        background-size: cover;
				   			-webkit-justify-content: space-between;
			       		justify-content: space-between;
			}
			&.menu-panel__project03{
				background: url("../images/mainvisual/mainvisual_a-4.jpg")no-repeat center center;
				-webkit-background-size: cover;
				     -o-background-size: cover;
				        background-size: cover;
				    			-webkit-justify-content: space-between;
			       		justify-content: space-between;
			}
			&.menu-panel__crosstalk01{
				background: url("../images/mainvisual/mainvisual_b-2-1.jpg")no-repeat center center;
				-webkit-background-size: cover;
				     -o-background-size: cover;
				        background-size: cover;
				    width: 100%;
				    height: 280px;
			}
			&.menu-panel__crosstalk02{
				background: url("../images/mainvisual/mainvisual_b-2-2.jpg")no-repeat center center;
				-webkit-background-size: cover;
				     -o-background-size: cover;
				        background-size: cover;
				    width: 100%;
				    height: 280px;
			}
			&.menu-panel__interview12{
				background: url("../images/mainvisual/mainvisual_b-3.jpg")no-repeat center center;
				-webkit-background-size: cover;
				     -o-background-size: cover;
				        background-size: cover;
				    			-webkit-justify-content: space-between;
			       		justify-content: space-between;
			}
			&.menu-panel__interview08{
				background: url("../images/mainvisual/mainvisual_b-4.jpg")no-repeat right center;
				-webkit-background-size: cover;
				     -o-background-size: cover;
				        background-size: cover;
				    			-webkit-justify-content: space-between;
			       		justify-content: space-between;
			}
			&.menu-panel__interview10{
				background: url("../images/mainvisual/mainvisual_b-5.jpg")no-repeat 80% center;
				-webkit-background-size: cover;
				     -o-background-size: cover;
				        background-size: cover;
				    			-webkit-justify-content: space-between;
			       		justify-content: space-between;
			}

			&.menu-panel__interview01{
				background: url("../images/mainvisual/mainvisual_b-6.jpg")no-repeat center center;
				-webkit-background-size: cover;
				     -o-background-size: cover;
				        background-size: cover;
				    			-webkit-justify-content: space-between;
			       		justify-content: space-between;
			}

			&.menu-panel__interview02{
				background: url("../images/mainvisual/mainvisual_b-7.jpg")no-repeat right center;
				-webkit-background-size: cover;
				     -o-background-size: cover;
				        background-size: cover;
				    			-webkit-justify-content: space-between;
			       		justify-content: space-between;
			}
			&.menu-panel__interview03{
				background: url("../images/mainvisual/mainvisual_b-8.jpg")no-repeat right bottom;
				-webkit-background-size: cover;
				     -o-background-size: cover;
				        background-size: cover;
				    			-webkit-justify-content: space-between;
			       		justify-content: space-between;
			}
			&.menu-panel__interview09{
				background: url("../images/mainvisual/mainvisual_b-9.jpg")no-repeat right center;
				-webkit-background-size: cover;
				     -o-background-size: cover;
				        background-size: cover;
				    			-webkit-justify-content: space-between;
			       		justify-content: space-between;
			}
			&.menu-panel__interview07{
				background: url("../images/mainvisual/mainvisual_b-10.jpg")no-repeat 90% center;
				-webkit-background-size: cover;
				     -o-background-size: cover;
				        background-size: cover;
				    			-webkit-justify-content: space-between;
			       		justify-content: space-between;
			}
			&.menu-panel__interview04{
				background: url("../images/mainvisual/mainvisual_b-11.jpg")no-repeat right top;
				-webkit-background-size: cover;
				     -o-background-size: cover;
				        background-size: cover;
				    			-webkit-justify-content: space-between;
			       		justify-content: space-between;
			}
			&.menu-panel__interview05{
				background: url("../images/mainvisual/mainvisual_b-12.jpg")no-repeat center top;
				-webkit-background-size: cover;
				     -o-background-size: cover;
				        background-size: cover;
				    			-webkit-justify-content: space-between;
			       		justify-content: space-between;
			}
			&.menu-panel__interview11{
				background: url("../images/mainvisual/mainvisual_b-13.jpg")no-repeat 80% center;
				-webkit-background-size: cover;
				     -o-background-size: cover;
				        background-size: cover;
				    			-webkit-justify-content: space-between;
			       		justify-content: space-between;
			}
			&.menu-panel__interview06{
				background: url("../images/mainvisual/mainvisual_b-14.jpg")no-repeat 90% center;
				-webkit-background-size: cover;
				-o-background-size: cover;
				background-size: cover;
				-webkit-justify-content: space-between;
				justify-content: space-between;
			}
			.project__number{
				dt{
					// font-size: 1.1rem;
					// font-weight: 500;
					font-size: 2rem;
				}
				dd{
					// font-size: 3vw;
					// font-weight: 900;
					font-size: 3rem;
					&.j-title{
						// font-size: 2vw;
						font-size: 1.8rem;
					}
				}
			}
			// p{
			// 	text-align: right;
			// 	font-weight: 900;
			// }
		}
	}


	.slide-dots{
		padding: 50px 0;
		text-align: center;
		li{
			display: inline-block;
			width: 10%;
			margin: 0 10px;
			opacity:0;
			button{
				position: relative;
				text-indent: -9999px;
				background-color: transparent;
				border: none;
				cursor: pointer;
				outline: none;
				padding: 0;
				appearance: none;
				width: 100%;
				height:5px;
				&:before{
					content: '';
					font-size: 20px;
					text-indent: 0px;
					position: absolute;
					top: 0;
					left: 0;
					background: #FFF;
					width: 100%;
					height:5px;
				}
			}
			&.slick-active{
				button:before{
					content: '';
					background: $main-color;
				}
			}
		}
	}


}