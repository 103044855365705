@charset "UTF-8";
@import "variables";
@import "common";
@import "module";


/**********************

HEADER_SETTINGS

***********************/


body{
	z-index:0;
	&.fixed{
		position: fixed;
		width:100%;
		height: 100%;
	}
}

header{
	position: fixed;
	z-index:1000;
	top:0;
	left:0;
	width: 100vw;
}


.hader-contener{
	position: relative;
	display: flex;
	align-items: center;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	// z-index:1000;
	// top:0;
	// left:0;
	width: 100vw;
	// height:80px;
	height: 90px;
	padding: 20px 50px;
	background: #FFF;
	.header-logo-box{
		position: absolute;
		left:2vw;
		top:50%;
		transform:translateY(-50%);
		z-index: 1;
		a.header-rogo{
			display: block;
			height: 40px;
			width: 40px;
			img{
				height: 100%;
			}
		}
	}
	.hader-round-btn{
		height: 60px;
		border-radius:80px;
		border: 2px solid $main-color;
		width: 12vw;
		-webkit-justify-content: center;
		justify-content: center;
		margin-left: 15px;
		z-index:1;
		padding: 0 15px;
		a{
			width: 100%;
			height: 100%;
			display: flex;
			-webkit-justify-content: center;
			justify-content: center;
			-webkit-align-items: center;
			align-items: center;
		}
		p.roundbtntext{
			display: inline-block;
			width: fit-content;
			font-size: 1.4rem;
			color: $main-color;
			font-weight: 600;
			line-height: 1.2;
		}
	}
	.menu-trigger{
		background: $main-color;
		z-index: 500;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		padding: 0 5px;
		p.triggerber{
			width: 20px;
			height:  15px;
			display: flex;
			margin-right:  10px;
			flex-direction: column;
			justify-content: space-between;
			span{
				display: block;
				width: 100%;
				height: 2px;
				background: #FFF;
			}
		}
		p.roundbtntext{
			color: #FFF;
		}
		&.is-show{
			border: 2px solid #fff;
		}
	}
	.header-circle-btn{
		width: 80px;
		height: 80px;
		border-radius: 50%;
		border: 2px solid $main-color;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 10px;
		z-index:1;
		padding: 2px;
		position: relative;
		&::after{
			position: absolute;
			content: '';
			background: url("../images/common/arrow_02.svg")no-repeat center center;
			-webkit-background-size: contain;
			     -o-background-size: contain;
			        background-size: contain;
			bottom: 5px;
			left: 0;
			right: 0;
			margin: auto;
			width: 14px;
			height: 14px;
		}
		&.past{
			background: rgba($main-color, 0.1);
		}
		a{
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin-bottom: 10px;
			span{
				display: inline-block;
				width: fit-content;
				color: $main-color;
				// font-size: 1.1rem;
				font-size: 1.2rem;
				letter-spacing: 0.05em;
				font-weight: 900;
				line-height: 1.2;
				& + span{
					margin-top: 2px;
					font-size: 1.5rem;
					text-align: center;
				}
			}
		}
		&.menu-trigger{
			background: $main-color;
			z-index: 500;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			padding: 0 5px;
			p.trigger-bar{
				width: 20px;
				height: 15px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				span{
					display: block;
					width: 100%;
					height: 2px;
					background: #FFF;
				}
			}
			p.menu-trigger-txt{
				margin-top: 8px;
				color: #FFF;
				// font-size: 1.1rem;
				font-size: 1.5rem;
				font-weight: 900;
				line-height: 1;
			}
			&.is-show{
				border: 2px solid #fff;
			}
		}
	}
	.mega-menu{
		position: fixed;
		top:0;
		left:0;
		width: 100vw;
		height: 100vh;
		width: 100%;
		z-index: 450;
		display: none;
		// padding: 80px 5vw 40px;
		padding: 90px 5vw 40px;
		overflow: hidden;
		&::after{
			z-index: -1;
			position: absolute;
			content:'';
			top:0;
			right:0;
			width: 0vw;
			height: 0vw;
			background: $main-color;
			// animation: mega-bg 2s forwards;
			transition:.3s all ease 0s;
		}
		&.is-show{
			display: block;
			&::after{
				width: 100vw;
				height: 100vw;
				z-index: 1;
				 animation: mega-bg .5s forwards;
			}
			ul.megalink-list{
				animation: mega-menu .5s forwards .5s;
			}
		}
		ul.megalink-list{
			width: 100%;
			height: 50%;
			display: flex;
			justify-content: flex-start;
			z-index: 10;
			transition:.3s all ease;
			opacity: 0;
			li{
				width: 23.5%;
				margin-right: 2%;
				&:nth-of-type(4){
					margin-right: 0;
				}
				.megalist-img{
					width: 100%;
					height: 15vh;
					margin-bottom: 10px;
					background-size: cover;
					img{
						width: 100%;
					}
				}
				p.megalink-title{
					display: block;
					width: 100%;
					position: relative;
					margin-bottom: 6px;
					padding-bottom: 1px;
					span.en{
						display: block;
						width: 100%;
						font-size: clamp(1.3rem, 4vh, 2.4rem); ;
						letter-spacing: 0;
						font-weight: 600;
						color: #FFF;
						line-height: 1;
						margin-bottom: 2vh;
					}
					span.jp{
						display: block;
						width: 100%;
						font-size: clamp(1.3rem, 2vh, 1.5rem); ;
						font-weight: 600;
						color: #FFF;
						margin-bottom: 8px;
						margin-top: -13px;
					}
					&::before{
						position: absolute;
						content:'';
						width: calc(100% - 20px);
						height: 2px;
						bottom:0;
						left:0;
						background: #FFF;
					}
					&::after{
						position: absolute;
						content:'＞';
						right:0;
						bottom:0;
						font-size: 2rem;
						font-weight: 600;
						color: #FFF;
						transform:translateY(50%) scaleX(.6);
					}
				}
				ul.megasublink-list{
					display: block;
					width: 100%;
					li{
						width: 100%;
						margin-bottom: -.5vh;
						background: none;
						a{
							display: block;
							width: 100%;
							color: #FFF;
							font-size: 2.2vh;
							font-weight: 600;
						}
					}
				}
				.menu00{
					background: url("../images/mainvisual/mainvisual_concept.jpg")no-repeat center center;
					background-size: cover;
				}
				.menu01{
					background: url("../images/mainvisual/mainvisual_a-1.jpg")no-repeat center center;
					background-size: cover;
				}
				.menu02{
					background: url("../images/mainvisual/mainvisual_b-7.jpg")no-repeat center center;
					background-size: cover;
				}
				.menu03{
					background: url("../images/mainvisual/mainvisual_c-menu.jpg")no-repeat top center;
					background-size: cover;
				}
				.menu04{
					background: url("../images/mainvisual/mainvisual_c-1.jpg")no-repeat center center;
					background-size: cover;
				}

				.menu05{
					background: url("../images/mainvisual/mainvisual_e-1.jpg")no-repeat bottom center;
					background-size: cover;
				}
				.menu06{
					background: url("../images/mainvisual/mainvisual_f.jpg")no-repeat center center;
					background-size: cover;
				}
				.menu07{
					background: url("../images/mainvisual/mainvisual_f-1.jpg")no-repeat center center;
					background-size: cover;
				}
				.menu08{
					background: url("../images/mainvisual/mainvisual_c-1_2.jpg")no-repeat center center;
					background-size: cover;
				}
			}
		}
	}
}

.btn-container {
	display: flex;
	justify-content: flex-end;
	background: $main-color;
	// border-bottom: 1px solid #FFF;
	box-shadow: 0px 2px 4px rgba(0,0,0,0.18);
	.header-entry-btn{
		width: 16%;
		height: 60px;
		border-right: 1px solid #FFF;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		background: rgba(#FFF, 0.2);
		&.past{
			background: rgba(#FFF, 0.1);
		}
		&::after{
			content: '';
			position: absolute;
			right: 16px;
			top: 0;
			bottom: 0;
			margin: auto;
			width: 10px;
			height: 10px;
			border-top: 1px solid #FFF;
			border-right: 1px solid #FFF;
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
		}
		&:first-child{
			border-left:  1px solid #FFF;
		}
		&:last-child{
			border-right: none;
		}
		a {
			width: 100%;
			color: #FFF;
			text-align: center;
			// padding: 10px ;
			display: block;
			span {
				display: block;
				font-weight: 700;
			font-size: 1.4rem;
			}
		}
		&:hover{
			background: #FFF;
			transition: .4s all ease ;
			&::after{
				content: '';
				position: absolute;
				right: 16px;
				top: 0;
				bottom: 0;
				margin: auto;
				width: 10px;
				height: 10px;
				border-top: 1px solid $main-color;
				border-right: 1px solid $main-color;
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);
			}
			a {
				width: 100%;
				color: $main-color;
				text-align: center;
			// padding: 10px ;
			display: block;
			span {
				display: block;
				font-weight: 700;
				font-size: 1.4rem;
			}
		}
		}
	}
}

.btn-float {
	position: absolute;
	right: 10px;
	top: 100px;
	width: 180px;
	transition: .5s all ease ;
	&.hide-out {
		transform:translateX(190px);
		transition: .8s all ease ;
	}
	.header-entry-btn{
		width: 100%;
		height: 60px;
		margin-bottom: 10px;
		border-radius: 5px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		// background: $main-color;
		border: 1px solid #FFF;
		background: rgba($main-color, 0.6);
		&.past{
			background: rgba($main-color, 0.8);
		}
		&::after{
			content: '';
			position: absolute;
			right: 16px;
			top: 0;
			bottom: 0;
			margin: auto;
			width: 8px;
			height: 8px;
			border-top: 2px solid #FFF;
			border-right: 2px solid #FFF;
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
		}
		a {
			width: 100%;
			color: #FFF;
			text-align: center;
			// padding: 10px ;
			display: block;
			span {
				display: block;
				font-weight: 700;
			font-size: 1.4rem;
			}
		}
		&:hover {
			background: #FFF;
			border: 1px solid $main-color;
			transition: .4s all ease ;
			&::after{
				content: '';
				position: absolute;
				right: 16px;
				top: 0;
				bottom: 0;
				margin: auto;
				width: 8px;
				height: 8px;
				border-top: 2px solid $main-color;
				border-right: 2px solid $main-color;
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);
			}
			a {
				width: 100%;
				color: $main-color;
				text-align: center;
			// padding: 10px ;
			display: block;
			span {
				display: block;
				font-weight: 700;
				font-size: 1.4rem;
			}
		}
	}
	}
}


@keyframes mega-bg {
	0% {
		width: 0vw;
		height: 0vh;
		border-radius:0 0 0 100vh;
		z-index: -1;
	}

	80% {
		width: 120vw;
		height: 120vh;
		border-radius:0 0 0 100vh;
		z-index: -1;
	}
	99% {
		width: 120vw;
		height: 120vh;
		border-radius:0 0 0 0vh;
		z-index: -1;
	}
	100% {
		width: 100vw;
		height: 100vh;
		border-radius:0 0 0 0vh;
		z-index: -1;
	}
}

@keyframes mega-menu {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}




/*********************************

main-visual

**********************************/

.mainvisual{
	width: 100%;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	height: 50vh;
	min-height: 450px;
	margin-top: 80px;
	background: #888;
	position: relative;
	p.title{
		text-align: center;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
		-webkit-transform: translateY(-50%) translateX(-50%);
		margin: auto;
		display: flex;
		flex-direction: column;
		.en{
			// font-size: 8vw;
			font-size: 9rem;
			line-height: 1;
			font-weight: 900;
			font-family: 'Prompt', sans-serif;
			font-style: italic;
			color: #FFF;
			text-shadow:0 1px 2px rgba(0,0,0,.20);
			position: relative;
			padding-bottom: 40px;
			margin-bottom: 30px;
			&::after{
				content: "";
				position: absolute;
				left: 50%;
				margin-left: -30px;
				bottom: 0;
				display: block;
				width: 60px;
				height: 3px;
				background: #FFF;
				box-shadow:0 1px 2px rgba(0,0,0,.20);
			}
		}
		.jp{
			// font-size: 1.6vw;
			font-size: 1.8rem;
			line-height: 1;
			letter-spacing: .5em;
			font-weight: 900;
			color: #FFF;
			text-shadow:0 1px 2px rgba(0,0,0,.20);
		}
	}
}


// .header{
// 	position: relative;
// 	position: fixed;
// 	display: flex;
// 	align-items: center;
// 	z-index:1000;
// 	top:0;
// 	left:0;
// 		width: 100vw;
// 		height:70px;
// 		padding: 20px 50px;
// 		-webkit-box-pack: justify;
// 		   -ms-flex-pack: justify;
// 		 justify-content: space-between;
// 		 height:70px;
// 		 background: #FFF;
// 	.top-page &{
// 		background: none;
// 	}
// }

.g-navi-logo{
	margin-right: auto;
}

.g-navi-logo img{
	display: block;
	height:40px;
}


.g-navi-menu010 img{
	display: block;
	height:20px;
}

.g-navi-btn__container{
	display: flex;
	text-align: center;
	.entry__btn{
		background: #FFF;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1.5rem;
		color: $main-color;
		padding: 5px 20px;
		margin-right: 20px;
		font-weight: bold;
		width: 200px;
		border: 1px solid $main-color;
	}
	.mypage__btn{
		display: flex;
		justify-content: center;
		align-items: center;
		background: $main-color;
		font-size: 1.5rem;
		color: #FFF;
		padding: 5px 20px;
		font-weight: bold;
		width: 200px;
	}
}


/**********************MENU_SETTINGS***********************/

.menu-panel{
	width: 100%;
	// margin-top: 100px;
	// height:calc(100% - 100px );
	background: #FFF;
	z-index:1000;
	text-align: center;
	// .inner{
	// }
}

.menu-panel img{
	height:100%;
}

.megamenu{
	background: rgba(166,187,199,.8);
	background: #FFF;
	z-index:1500;
	position: fixed;
	padding-top: 75px;
	top:0;
	left:0;
	width: 100%;
	height:100%;
	display: none;
	// overflow-y: scroll;
}

.menu-panel{
	.inner{
		.content-title{
			position: static;
			top:auto;
			left:auto;
			transform: translateY(0) translateX(0);
			    -webkit-transform: translateY(0) translateX(0);
			margin:0 0 30px 0;
			dt{
				font-size: 2vw;
				margin-bottom: 20px;
				padding-bottom: 20px;
			}
			&.main-color{
				dt{
					color:$main-color;
				}
			}
		}
		.menu-panel-layout{
			display: flex;

			height: calc(100vh - 245px);
			max-height: 800px;

			.text{
				.english{
					font-family: 'Prompt', sans-serif;
					font-style: italic;
					font-size: 1.8vw;
				}
				.japanese{
				font-size: .6vw;
				}
			}
			.panel-large{
				width: 0;
				a{
					background: #666;
					display: block;
					position: relative;
					width: 100%;
					padding-top: 100%;
					color:#fff;
					.text{
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translateY(-50%) translateX(-50%);
						-webkit-transform: translateY(-50%) translateX(-50%);
						margin: auto;
					}
				}
			}
			.panel-list{
				width: 100%;
				display: flex;
				-webkit-flex-wrap: wrap;
				    -ms-flex-wrap: wrap;
				        flex-wrap: wrap;
				        align-items: flex-start;
				a{
					width: 33.33333%;
					position: relative;
					display:block;
					background: #666;
					position: relative;
					// padding-top: 12.5%;

					// height:300px;
					height: 50%;
					max-height: 400px;

					color:#fff;
					&.p01{
						background: url("../images/mainvisual/mainvisual_a-1.jpg")no-repeat center center;
						-webkit-background-size: cover;
						     -o-background-size: cover;
						        background-size: cover;
					}
					&.p02{
						background: url("../images/mainvisual/mainvisual_b-1.jpg")no-repeat center center;
						-webkit-background-size: cover;
						     -o-background-size: cover;
						        background-size: cover;
					}
					&.p03{
						background: url("../images/mainvisual/mainvisual_c-1.jpg")no-repeat left center;
						-webkit-background-size: cover;
						     -o-background-size: cover;
						        background-size: cover;
					}
					&.p04{
						background: url("../images/mainvisual/mainvisual_d-1.jpg")no-repeat left center;
						-webkit-background-size: cover;
						     -o-background-size: cover;
						        background-size: cover;
					}
					&.p05{
						background: url("../images/mainvisual/mainvisual_e-1.jpg")no-repeat right center;
						-webkit-background-size: cover;
						     -o-background-size: cover;
						        background-size: cover;
					}
					&.p06{
						background: url("../images/mainvisual/mainvisual_f-1.jpg")no-repeat center center;
						-webkit-background-size: cover;
						     -o-background-size: cover;
						        background-size: cover;
					}
					.text{
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translateY(-50%) translateX(-50%);
						-webkit-transform: translateY(-50%) translateX(-50%);
						margin: auto;
						width: 100%;
					}
				}
			}
		}
	}
}


.menu-search{
	width: 60%;
	margin:20px auto 0;
}

.l-menu-trigger{
	position: fixed;
	top: 10px;
	left: 50%;
	width: 60px;
	height:60px;
	z-index:3000!important;
	transition: all 0.3s ease;
	transform:translateX(-50%);
	-webkit-transform:translateX(-50%);
	margin: auto;
}

.l-menu-trigger.is-show{
	transition: all 0.3s ease;
}




/*********************************

main-visual


**********************************/

.mainvisual{
	// width: 100vw;
	// height: 60vh;
	// position: relative;
	// padding:100px;
	// overflow: hidden;
	// background: #efefef;

	// margin-top: 70px;

	// .top-page &{
	// 	height: 100vh;
	// }

	.person &{
		background: url("../images/mainvisual/mainvisual_b-1.jpg")no-repeat center center;
		-webkit-background-size: cover;
		     -o-background-size: cover;
		        background-size: cover;
	}
	.project &{
		background: url("../images/mainvisual/mainvisual_a-1.jpg")no-repeat center center;
		-webkit-background-size: cover;
		     -o-background-size: cover;
		        background-size: cover;
	}
	.crosstalk01 &{
		background: url("../images/mainvisual/mainvisual_b-2-1.jpg")no-repeat center 10%;
		-webkit-background-size: cover;
		     -o-background-size: cover;
		        background-size: cover;
	}
	.crosstalk02 &{
		background: url("../images/mainvisual/mainvisual_b-2-2.jpg")no-repeat center 5%;
		-webkit-background-size: cover;
		     -o-background-size: cover;
		        background-size: cover;
	}
	.interview &{
		height: 70vh;
	}
	.interview01 &{
		background: url("../images/mainvisual/mainvisual_b-3.jpg")no-repeat center 20%;
		-webkit-background-size: cover;
		     -o-background-size: cover;
		        background-size: cover;
	}
	.interview02 &{
		background: url("../images/mainvisual/mainvisual_b-4.jpg")no-repeat center 20%;
		-webkit-background-size: cover;
		     -o-background-size: cover;
		        background-size: cover;
	}
	.interview03 &{
		background: url("../images/mainvisual/mainvisual_b-5.jpg")no-repeat center 25%;
		-webkit-background-size: cover;
		     -o-background-size: cover;
		        background-size: cover;
	}
	.interview04 &{
		background: url("../images/mainvisual/mainvisual_b-6.jpg")no-repeat center 20%;
		-webkit-background-size: cover;
		     -o-background-size: cover;
		        background-size: cover;
	}
	.interview05 &{
		background: url("../images/mainvisual/mainvisual_b-7.jpg")no-repeat center 40%;
		-webkit-background-size: cover;
		     -o-background-size: cover;
		        background-size: cover;
	}
	.interview06 &{
		background: url("../images/mainvisual/mainvisual_b-8.jpg")no-repeat center 40%;
		-webkit-background-size: cover;
		     -o-background-size: cover;
		        background-size: cover;
	}
	.interview07 &{
		background: url("../images/mainvisual/mainvisual_b-9.jpg")no-repeat center 40%;
		-webkit-background-size: cover;
		     -o-background-size: cover;
		        background-size: cover;
	}
	.interview08 &{
		background: url("../images/mainvisual/mainvisual_b-10.jpg")no-repeat center 25%;
		-webkit-background-size: cover;
		     -o-background-size: cover;
		        background-size: cover;
	}
	.interview09 &{
		background: url("../images/mainvisual/mainvisual_b-11.jpg")no-repeat center 10%;
		-webkit-background-size: cover;
		     -o-background-size: cover;
		        background-size: cover;
	}
	.interview10 &{
		background: url("../images/mainvisual/mainvisual_b-12.jpg")no-repeat center 13%;
		-webkit-background-size: cover;
		     -o-background-size: cover;
		        background-size: cover;
	}
	.interview11 &{
		background: url("../images/mainvisual/mainvisual_b-13.jpg")no-repeat center 15%;
		-webkit-background-size: cover;
		     -o-background-size: cover;
		        background-size: cover;
	}
	.interview12 &{
		background: url("../images/mainvisual/mainvisual_b-14.jpg")no-repeat center 15%;
		-webkit-background-size: cover;
		     -o-background-size: cover;
		        background-size: cover;
	}
	.interview13 &{
		background: url("../images/mainvisual/mainvisual_b-15.jpg")no-repeat center 15%;
		-webkit-background-size: cover;
		     -o-background-size: cover;
		        background-size: cover;
	}
	.interview14 &{
		background: url("../images/mainvisual/mainvisual_b-16.jpg")no-repeat center 15%;
		-webkit-background-size: cover;
		     -o-background-size: cover;
		        background-size: cover;
	}
	.interview15 &{
		background: url("../images/mainvisual/mainvisual_b-17.jpg")no-repeat center 15%;
		-webkit-background-size: cover;
		     -o-background-size: cover;
		        background-size: cover;
	}
	.interview16 &{
		background: url("../images/mainvisual/mainvisual_b-18.jpg")no-repeat center 15%;
		-webkit-background-size: cover;
		     -o-background-size: cover;
		        background-size: cover;
	}


	.information &{
		background: url("../images/mainvisual/mainvisual_c-1.jpg")no-repeat center 40%;
		-webkit-background-size: cover;
		     -o-background-size: cover;
		        background-size: cover;
	}

	.business &{//現aboutus
		background: url("../images/mainvisual/mainvisual_c-1.jpg")no-repeat center 40%;
		-webkit-background-size: cover;
		     -o-background-size: cover;
		        background-size: cover;
	}
	.message &{
		background: url("../images/mainvisual/mainvisual_e-1-1.jpg")no-repeat center -40px;
		-webkit-background-size: cover;
		     -o-background-size: cover;
		        background-size: cover;
	}
	.aboutus &{//現company
		background: url("../images/mainvisual/mainvisual_d-1.jpg")no-repeat center 5%;
		-webkit-background-size: cover;
		     -o-background-size: cover;
		        background-size: cover;
	}
	.sustainability &{
		background: url("../images/mainvisual/mainvisual_d-1.jpg")no-repeat center 5%;
		-webkit-background-size: cover;
		     -o-background-size: cover;
		        background-size: cover;
	}
	.recruit &{
		background: url("../images/mainvisual/mainvisual_f-1.jpg")no-repeat center center;
		-webkit-background-size: cover;
		     -o-background-size: cover;
		        background-size: cover;
	}
	.group-companies &{
		background: url("../images/mainvisual/mainvisual_c-1_2.jpg")no-repeat center center;
		-webkit-background-size: cover;
		     -o-background-size: cover;
		        background-size: cover;
	}

	.survey &{
		background: url("../images/mainvisual/mainvisual_f.jpg")no-repeat center center;
		-webkit-background-size: cover;
		     -o-background-size: cover;
		        background-size: cover;
	}



	.talkindex &{
		// background: url("../images/mainvisual/mainvisual_c.jpg")no-repeat center 80%;
		background: url("../images/mainvisual/mainvisual_b-1.jpg")no-repeat center center;
		-webkit-background-size: cover;
		     -o-background-size: cover;
		        background-size: cover;
	}
	.page-title__section{
		text-align: center;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
		-webkit-transform: translateY(-50%) translateX(-50%);
		margin: auto;
		.page-title__eg{
			// font-size: 8vw;
			font-size: 7vw;
			line-height: 1;
			font-weight: 900;
			font-family: 'Prompt', sans-serif;
			font-style: italic;
			color: #FFF;
			text-shadow:0 1px 2px rgba(0,0,0,.20);
			& + .page-title__jp{
				margin-top: 40px;
			}
		}
		.page-title__jp{
			// font-size: 1.6vw;
			// letter-spacing: .5em;
			// font-weight: 900;
			// color: #FFF;
			// text-shadow:0 1px 2px rgba(0,0,0,.20);
			display: inline-block;
			padding: 5px 10px 5px 20px;
			background: #FFF;
			color: $main-color;
			font-size: 1.8vw;
			font-weight: 900;
			letter-spacing: .3em;
			text-shadow: none;
			box-shadow:0 1px 2px rgba(0,0,0,.20);
		}
	}
}

/*********************************

container-settings

**********************************/

.content-inner{
	width: 100%;
	margin: 0 auto;
	max-width: 1000px;
	&.requirements{
		width: 100%;
		max-width: 1000px;
	}
}

.section-inner{
	width: 80%;
	margin: 0 auto;
}

.content-menu-title__section{
	padding: 10% 5%;
	position: relative;
	&.bg-grey{
	background: $bg-grey;
	}
}
.content-menu-title__section{
	padding: 10% 5%;
	position: relative;
	&.bg-grey{
	background: $bg-grey;
	}
}
.content-section{
	padding: 0 5% 10%;
	position: relative;
	&.bg-grey{
	background: $bg-grey;
	}
}
.content-menu__title{
	text-align: center;
	position: relative;
	z-index: 100;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateY(-50%) translateX(-50%);
	-webkit-transform: translateY(-50%) translateX(-50%);
	margin: auto;
	color:$main-color;
	text-align: center;
}
.content-menu__title dt{
	position: relative;
	padding-bottom: 10px;
	margin-bottom: 20px;
	font-size: 7rem;
	font-weight: 900;
	font-family: 'Prompt', sans-serif;
	font-style: italic;
}


.content-menu__title dt::after{
	content:"";
	width: 50px;
	height: 5px;
	background: $main-color;
	display: inline-block;
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	margin: auto;
	margin-left: -30px;
}
.content-menu__title dd{
	font-size: 1.8rem;
}

.content-title{
	text-align: center;
	position: relative;
	z-index: 100;
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translateY(-50%) translateX(-50%);
	-webkit-transform: translateY(-50%) translateX(-50%);
	margin: auto;
	color:#fff;
	text-align: center;
}

.pt80{
	padding-top: 80px;
}











footer{
	.l-footer{
		.fotter-backbtn-box{
			width: 100%;
			display: flex;
			justify-content: center;
			margin-bottom: 5vh;
			margin-top: 8vh;
			a{
				display: flex;
				width: 90px;
				height: 90px;
				position: relative;
				border-radius:50%;
				border:2px solid $main-color;
				align-items: center;
				p{
					display: block;
					width: 100%;
					font-size: 1.3rem;
					font-weight: 900;
					color: $main-color;
					text-align: center;
					letter-spacing: 0;
					transform:translateY( 5px);
				}
				&::after{
					content:'＞';
					position: absolute;
					left: 50%;
					top: 16px;
					color: $main-color;
					font-weight: 600;
					transform:translateX(-50%)  rotate(-90deg) scaleX( .7);
				}
			}
		}
		.fotter-entry-box{
			display: flex;
			width: 100%;
			justify-content:  center;
			margin-bottom: 3vh;
			a{
				width: 300px;
				border:2px solid $main-color;
				font-size: 1.9rem;
				font-weight: 600;
				padding: 10px 20px;
				border-radius:40px;
				color: $main-color;
				text-align: center;
				&.mypage{
					margin-left: 20px;
					background: $main-color;
					color: #FFF;
				}
			}
		}

		.footer-entry-box{
			width: 100%;
			display: flex;
			justify-content: center;
			margin-bottom: 3vh;
			a{
				display: inline-block;
				border: 2px solid $main-color;
				font-size: 1.8rem;
				letter-spacing: 0.05em;
				font-weight: 600;
				padding: 10px 40px;
				border-radius:40px;
				color: $main-color;
				text-align: center;
				margin-right: 20px;
				&.past{
					background: rgba($main-color, 0.1);
				}
				&:last-child{
					margin-right: 0;
				}
			}
		}

	}
}



































.footer-btn__container {
	display: flex;
	font-weight: 900;
	text-align: center;
	font-size: 2.4rem;
	box-shadow: 16px 16px 36px rgba(131,161,181,.1);
	.footer-entry__btn {
		width: 50%;
		padding: 20px 0;
		color: $main-color;
		background: #FFF;
	}
	.footer-mypage__btn {
		width: 50%;
		padding: 20px 0;
		color: #FFF;
		background: $main-color;
	}
}
.footer-sitemap__section {
	background: $bg-grey;
	padding: 30px 5% 0;
	p {
		width: 30%;
		margin-bottom: 30px;
		img {
			width: 100%;
		}
	}
	.footer-sitemap__container {
		display: flex;
		padding-bottom: 100px;
		-webkit-flex-wrap: wrap;
		    -ms-flex-wrap: wrap;
		        flex-wrap: wrap;
		li {
			width: 23.5%;
			margin-right: 2%;
			margin-bottom: 40px;
			&:nth-child(4n){
				margin-right: 0;
			}
			dt {
				color: $main-color;
				border-bottom: 1px solid;
				font-weight: 900;
				padding-bottom: 5px;
				margin-bottom: 5px;
			}
			dd {
				a {
					color: $main-color;
				}
			}
		}
	}
}
.page-top__btn {
	background: $bg-grey;
	text-align: center;
	height: 40px;
	position: relative;
	a {
		width: 200px;
		color: $main-color;
		background: #FFF;
		padding: 10px 20px;
		font-size: 2rem;
		font-weight: bold;
		font-family: 'Prompt', sans-serif;
font-style: italic;
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		margin: 0 auto;
	}
}
.footer-copyright__section {
	box-shadow:0px 9px 3px -3px rgba(131,161,181,.1) inset;
	display: flex;
	background: $main-color;
	color: #FFF;
	padding: 10px 5%;
	height: 80px;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	.footer-links__container {
		display: flex;
		color: #FFF;
		font-size: 1.5rem;
		li {
			border-right: 1px solid;
			margin-right: 10px;
			padding-right: 10px;
			a {
				color: #FFF;
			}
		}
	}
	.footer-copyright {
		color: #FFF;
		font-size: 1.5rem;
	}
}
.menu-panel__container{
	display: flex;
	padding: 0 5%;
	-webkit-flex-wrap: wrap;
	    -ms-flex-wrap: wrap;
	        flex-wrap: wrap;
	// -webkit-justify-content: center;
	//         justify-content: center;
	&.person-index{
		padding-top: 50px;
	}
	a{
		width: 32%;
		background: rgba($main-color,.3);
		color: #FFF;
		padding: 10px;
		margin-right: 2%;
		height: 300px;
		display: flex;
		-webkit-flex-direction: column;
		    -ms-flex-direction: column;
		        flex-direction: column;
		-webkit-justify-content: center;
		        justify-content: center;
		margin-bottom: 20px;
		&:nth-child(3n){
			margin-right: 0;
		}
		&.menu-panel__project01{
			background: url("../images/mainvisual/mainvisual_a-2.jpg")no-repeat center center;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
			        -webkit-justify-content: space-between;
		        justify-content: space-between;
		}
		&.menu-panel__project02{
			background: url("../images/mainvisual/mainvisual_a-3.jpg")no-repeat center center;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
			        -webkit-justify-content: space-between;
		        justify-content: space-between;
		}
		&.menu-panel__project03{
			background: url("../images/mainvisual/mainvisual_a-4.jpg")no-repeat center center;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
			        -webkit-justify-content: space-between;
		        justify-content: space-between;
				position: relative;
		}
		&.menu-panel__crosstalk01{
			background: url("../images/mainvisual/mainvisual_b-2-1.jpg")no-repeat center center;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
			        -webkit-justify-content: space-between;
		        justify-content: space-between;
			    width: 44%;
			    height: 400px;
		}
		&.menu-panel__crosstalk02{
			background: url("../images/mainvisual/mainvisual_b-2-2.jpg")no-repeat center center;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
			        -webkit-justify-content: space-between;
		        justify-content: space-between;
			    width: 44%;
			    height: 400px;
		}
		&.menu-panel__interview12{
			background: url("../images/mainvisual/mainvisual_b-3.jpg")no-repeat 60% center;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
			        -webkit-justify-content: space-between;
		        justify-content: space-between;
		}
		&.menu-panel__interview08{
			background: url("../images/mainvisual/mainvisual_b-4.jpg")no-repeat right center;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
			        -webkit-justify-content: space-between;
		        justify-content: space-between;
		}
		&.menu-panel__interview10{
			background: url("../images/mainvisual/mainvisual_b-5.jpg")no-repeat 90% center;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
			        -webkit-justify-content: space-between;
		        justify-content: space-between;
		}

		&.menu-panel__interview01{
			background: url("../images/mainvisual/mainvisual_b-6.jpg")no-repeat center center;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
			        -webkit-justify-content: space-between;
		        justify-content: space-between;
		}
		&.menu-panel__interview02{
			background: url("../images/mainvisual/mainvisual_b-7.jpg")no-repeat 80% center;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
			        -webkit-justify-content: space-between;
		        justify-content: space-between;
		}
		&.menu-panel__interview03{
			background: url("../images/mainvisual/mainvisual_b-8.jpg")no-repeat center center;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
			        -webkit-justify-content: space-between;
		        justify-content: space-between;
		}
		&.menu-panel__interview09{
			background: url("../images/mainvisual/mainvisual_b-9.jpg")no-repeat right center;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
			        -webkit-justify-content: space-between;
		        justify-content: space-between;
		}
		&.menu-panel__interview07{
			background: url("../images/mainvisual/mainvisual_b-10.jpg")no-repeat 80% center;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
			        -webkit-justify-content: space-between;
		        justify-content: space-between;
		}
		&.menu-panel__interview04{
			background: url("../images/mainvisual/mainvisual_b-11.jpg")no-repeat 80% center;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
			        -webkit-justify-content: space-between;
		        justify-content: space-between;
		}
		&.menu-panel__interview05{
			background: url("../images/mainvisual/mainvisual_b-12.jpg")no-repeat 70% center;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
			        -webkit-justify-content: space-between;
		        justify-content: space-between;
		}
		&.menu-panel__interview11{
			background: url("../images/mainvisual/mainvisual_b-13.jpg")no-repeat 70% center;
			-webkit-background-size: cover;
			     -o-background-size: cover;
			        background-size: cover;
			        -webkit-justify-content: space-between;
		        justify-content: space-between;
		}
		&.menu-panel__interview06{
			background: url("../images/mainvisual/mainvisual_b-14.jpg")no-repeat right center;
			-webkit-background-size: cover;
			-o-background-size: cover;
			background-size: cover;
			-webkit-justify-content: space-between;
			justify-content: space-between;
		}

		&.menu-panel__interview13{
			background: url("../images/mainvisual/mainvisual_b-15.jpg")no-repeat 60% center;
			-webkit-background-size: cover;
			-o-background-size: cover;
			background-size: cover;
			-webkit-justify-content: space-between;
			justify-content: space-between;
		}
		&.menu-panel__interview14{
			background: url("../images/mainvisual/mainvisual_b-16.jpg")no-repeat right center;
			-webkit-background-size: cover;
			-o-background-size: cover;
			background-size: cover;
			-webkit-justify-content: space-between;
			justify-content: space-between;
		}
		&.menu-panel__interview15{
			background: url("../images/mainvisual/mainvisual_b-17.jpg")no-repeat 70% center;
			-webkit-background-size: cover;
			-o-background-size: cover;
			background-size: cover;
			-webkit-justify-content: space-between;
			justify-content: space-between;
		}
		&.menu-panel__interview16{
			background: url("../images/mainvisual/mainvisual_b-18.jpg")no-repeat right center;
			-webkit-background-size: cover;
			-o-background-size: cover;
			background-size: cover;
			-webkit-justify-content: space-between;
			justify-content: space-between;
		}


		.project__number{
			dt{
				font-size: 1.1rem;
				font-weight: 500;
			}
			dd{
				font-size: 2.6vw;
				font-weight: 900;
				&.j-title{
					font-size: 2vw;
				}
			}
		}
		.project__name{
			color: $main-color;
			background: rgba(#FFF,.9);
			padding: 3px 10px;
			text-shadow:0 1px 2px rgba(255,255,255,.20);
			dt{
				font-size: 1.4rem;
				font-weight: 500;
			}
			dd{
				font-size: 1.4rem;
				font-weight: 900;
				&.j-title{
					font-size: 2vw;
				}
			}
		}
		p{
			text-align: right;
			font-weight: 900;
		}
	}
}
// }

/*.is-show .vertical-title span:nth-of-type(2){
	opacity:1;
	transition: all .8s ease .4s;
}*/

// slider-settings


a.btn-wh{
	display: block;
	text-align: center;
	width: 100%;
	color:$main-color;
	background: #FFF;
	padding: 20px;
	box-shadow: 0px 2px 4px rgba(0,0,0,0.18);
	font-family: 'Prompt', sans-serif;
font-style: italic;
	font-weight: bold;
	font-size: 2rem;
	font-style: italic;
	transition: all .4s ease .1s;
	margin-bottom: 20px;
	&:hover{
		padding: 25px  20px 15px;
		box-shadow:none;
	}
}
.l-person{
	position: relative;
	.btn-block{
		opacity:0;
		margin-left: -20px;
	}
	&.is-show{
		.btn-block{
			opacity:1;
			margin-left: 0;
			transition: all .4s ease 2.4s;
		}
	}
}
a.btn-main-color{
	display: block;
	text-align: center;
	width: 40%;
	// margin:30px auto 5%;
	margin:60px auto 3%;
	border: 1px solid $main-color;
	color:$main-color;
	padding: 20px;
	font-family: 'Prompt', sans-serif;
font-style: italic;
	font-weight: bold;
	font-size: 2rem;
	font-style: italic;
	transition: all .4s ease .1s;
	text-decoration:none;
	&:hover{
		padding: 25px  20px 15px;
		box-shadow:none;
		color:#fff;
		background: $main-color;
	}
}

a.btn-white-border{
	display: block;
	text-align: center;
	width: 40%;
	margin:5% auto;
	border: 1px solid #fff;
	color:#fff;
	padding:10px 20px;
	font-family: 'Prompt', sans-serif;
font-style: italic;
	font-weight: bold;
	font-size: 2rem;
	font-style: italic;
	transition: all .4s ease .2s;
	&:hover{
		padding:10px 20px;
		box-shadow:none;
		color:#fff;
		background: $main-color;
		transition: all .4s ease .2s;
	}
}


.l-person-slider{
	position: relative;
	overflow: hidden;
	margin-bottom: 8vh;
	.btn-block{
		opacity:0;
		margin-left: -20px;
	}
	&.is-show{
		.btn-block{
			opacity:1;
			margin-left: 0;
			transition: all .4s ease 2.4s;
		}
	}
}



.person-image{
width: 100%;
position: relative;
z-index: 100;
opacity:0;
}

.person-image img{
	width: 100%;
}

.is-show .person-image.i01{
	width: 100%;
	opacity:1;
	transition: all .4s ease 1s;
}

.person-img-list{
	display: flex;
}

.person-img-list .person-image{
	width: 25%;
}

.person-img-list .person-image{
	width: 100%;
}

.is-show .person-image.i02{
	width: 100%;
	opacity:1;
	transition: all .4s ease .6s;
}

.is-show .person-image.i03{
	width: 100%;
	opacity:1;
	transition: all .4s ease .8s;
}

.is-show .person-image.i04{
	width: 100%;
	opacity:1;
	transition: all .4s ease 1s;
}

.is-show .person-image.i05{
	width: 100%;
	opacity:1;
	transition: all .4s ease 1.2s;
}
.is-show .person-image.i06{
	width: 100%;
	opacity:1;
	transition: all .4s ease 1.4s;
}
.is-show .person-image.i07{
	width: 100%;
	opacity:1;
	transition: all .4s ease 1.6s;
}
.is-show .person-image.i08{
	width: 100%;
	opacity:1;
	transition: all .4s ease 1.8s;
}
.is-show .person-image.i09{
	width: 100%;
	opacity:1;
	transition: all .4s ease 2.0s;
}
.is-show .person-image.i10{
	width: 100%;
	opacity:1;
	transition: all .4s ease 2.2s;
}
.is-show .person-image.i11{
	width: 100%;
	opacity:1;
	transition: all .4s ease 2.4s;
}
.is-show .person-image.i12{
	width: 100%;
	opacity:1;
	transition: all .4s ease 2.6s;
}
.is-show .person-image.i13{
	width: 100%;
	opacity:1;
	transition: all .4s ease 2.8s;
}
.is-show .person-image.i13{
	width: 100%;
	opacity:1;
	transition: all .4s ease 3.0s;
}
.is-show .person-image.i14{
	width: 100%;
	opacity:1;
	transition: all .4s ease 3.2s;
}
.is-show .person-image.i15{
	width: 100%;
	opacity:1;
	transition: all .4s ease 3.4s;
}
.is-show .person-image.i16{
	width: 100%;
	opacity:1;
	transition: all .4s ease 3.6s;
}
.is-show .person-image.i17{
	width: 100%;
	opacity:1;
	transition: all .4s ease 3.8s;
}



.slide-dots{
	padding: 50px 0;
  text-align: center;
  li{
    display: inline-block;
    width: 100px;
    margin: 0 10px;
    opacity:0;
    button{
      position: relative;
      text-indent: -9999px;
      background-color: transparent;
             border: none;
             cursor: pointer;
             outline: none;
             padding: 0;
             appearance: none;
             width: 100%;
             height:5px;
      &:before{
        content: '';
        font-size: 20px;
        text-indent: 0px;
        position: absolute;
        top: 0;
        left: 0;
        background: #FFF;
        width: 100%;
        height:5px;
      }
    }
    &.slick-active{
      button:before{
        content: '';
        background: $main-color;
      }
    }
  }
.is-show &{
	li:nth-of-type(1){
		opacity:1;
		transition: all .4s ease .6s;
	}
	li:nth-of-type(2){
		opacity:1;
		transition: all .4s ease .8s;
	}
	li:nth-of-type(3){
		opacity:1;
		transition: all .4s ease 1s;
	}
	li:nth-of-type(4){
		opacity:1;
		transition: all .4s ease 1.2s;
	}
	li:nth-of-type(5){
		opacity:1;
		transition: all .4s ease 1.4s;
	}
	li:nth-of-type(6){
		opacity:1;
		transition: all .4s ease 1.6s;
	}
	li:nth-of-type(7){
		opacity:1;
		transition: all .4s ease 1.8s;
	}
	li:nth-of-type(8){
		opacity:1;
		transition: all .4s ease 2s;
	}
	li:nth-of-type(9){
		opacity:1;
		transition: all .4s ease 2.2s;
	}
	li:nth-of-type(10){
		opacity:1;
		transition: all .4s ease 2.4s;
	}
	li:nth-of-type(11){
		opacity:1;
		transition: all .4s ease 2.6s;
	}
	li:nth-of-type(12){
		opacity:1;
		transition: all .4s ease 2.8s;
	}
}
}

.person-square{
	width: 90%;
position: relative;
z-index:-1;
	position: absolute;
	height:140%;
	top:-2%;
	left:100%;
	-webkit-background-size: cover;
	     -o-background-size: cover;
	        background-size: cover;
}

.is-show .person-square{
	width: 90%;
	position: absolute;
	height:140%;
	top:-2%;
	left:10%;
	background: -webkit-linear-gradient(90deg, rgba(78, 165, 158, .2), rgba(61, 117, 113, .4));
	background: linear-gradient(90deg, rgba(78, 165, 158, .2), rgba(61, 117, 113, .4));
	        transition: all .4s ease 1s;

}

a{
	&.no-link{
	color:rgba($main-color,0.5)!important;
	opacity:1;
	cursor: default;
	pointer-events: none!important;
	}
	&.no-link.panel{
	color:rgba(255,255,255,0.5)!important;
	opacity:1;
	cursor: default;
	pointer-events: none!important;
	}
	.sitemap &{
		&.no-link{
		color:rgba(255,255,255,0.5)!important;
		opacity:1;
		cursor: default;
		pointer-events: none!important;
		}
	}
}



@import "style-res";
