@charset "UTF-8";
/*見出し用*/

@import url('https://fonts.googleapis.com/css?family=Roboto+Mono:700i&display=swap');
// font-family: 'Prompt', sans-serif;
@import url('https://fonts.googleapis.com/css?family=Prompt&display=swap');
// font-family: 'Prompt', sans-serif;
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
/*font-family: 'Roboto', sans-serif;*/


/* reset */
*,
*:after,
*:before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

html {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	vertical-align: baseline;
	background: transparent;
	font-size: 62.5%;
	width:100%;
	height:100%;
	font-family: sans-serif;
}

body {
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	color: #000;
	line-height: 1.5;
	vertical-align: baseline;
	letter-spacing: 0.1em;
	font-smooth:always;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
	background-color: transparent;
	font-size: 16px;
	height:100%;
}

div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
margin: 0;
padding: 0;
border: 0;
outline: 0;
font-size: 100%;
vertical-align: baseline;
background: transparent;
font-weight: normal;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, textarea {
display: block;
}

ul { list-style: none; list-style-position:outside; margin:0; padding:0;  }
ul li { margin:0; padding:0; list-style: none;}
ol { list-style: decimal; list-style-position:inside; }
ol li { margin-bottom:5px;}
li img {
	vertical-align: bottom;
}
div img {
	vertical-align: bottom;
}

caption,th { text-align: left; }
q:before,q:after { content: ''; }
a img,:link img,:visited img {border: none;}

blockquote, q {
quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
content: '';
content: none;
}

strong{
	font-weight: bold;
}
del {
	text-decoration: line-through;
}
input, select {
	vertical-align:middle;
}
abbr[title], dfn[title] {
	border-bottom:1px dotted #000;
	cursor:help;
}
table {
	border-collapse:collapse;
	border-spacing:0;
}
ins {
	background-color:#dfdfdf;
	color:#000;
	text-decoration:none;
}
mark {
	background-color:#dfdfdf;
	color:#000;
	font-style:italic;
	font-weight: bold;
}
hr {
	display:block;
	height:1px;
	border:0;
	border-top:1px solid #dfdfdf;
	margin:1em 0;
	padding:0;

}

input::-webkit-input-placeholder {
	color: #666;
	font-size: 14px;
	font-size: 1.4rem;
}
input:-moz-placeholder {
	color: #666;
	font-size: 14px;
	font-size: 1.4rem;
}

img{
	margin:0;
	padding:0;
}

a {
margin: 0;
padding: 0;
font-size: 100%;
vertical-align: baseline;
background: transparent;
cursor: pointer;
outline: none;
text-decoration: none;
}
a:link { text-decoration:none; color:#363636;}
a:visited { text-decoration: underline; color:#363636;}
a:active, a:focus{opacity:0.7; outline:none;}
a:hover {
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	opacity:0.7;
 }

/*________________ ■全体設定 ________________*/



body{
	width: 100%;
	height:100%;
	background: #fff;
}


/* ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

■共通設定

■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■*/
.serif{
	font-family: 'Hiragino Mincho ProN', 'Yu Mincho', Georgia, serif;
}



/* 1字下げ________________*/
.in_atd {
	padding-left: 1em;
	text-indent: -1em;
}


/* color________________*/
.attention_red{
	color: #A30008;
}



/* clear________________*/
.clear{
	clear:both;
}

/*非表示*/
.hide-content{
	display: none;
}

.lock {
	overflow:hidden;
}
/* ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

レスポンシブ 表示切り替え用 汎用設定

■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■*/



/* responsive*/
.mb-object{display:none!important;}
.pc-object{display: block!important;}
.pc-object_fl{display: flex!important;}
@media screen and (max-width: 834px ) {
.mb-object{display: block!important;}
.pc-object{display:none!important;}
.pc-object_fl{display:none!important;}
}
